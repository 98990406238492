import React, { useContext, useEffect, useRef, useState } from 'react'
import useSortableData from '../../../hooks/useSortableData';
import useDarkMode from '../../../hooks/useDarkMode';
import PaginationButtons, { dataPagination } from '../../PaginationButtons';
import { useFormik } from 'formik';
import Button, { ButtonGroup } from '../../bootstrap/Button';
import { toasts } from '../../../services/toast.service';
import Page from '../../../layout/Page/Page';
import Collapse from '../../bootstrap/Collapse';
import Card, { CardActions, CardBody, CardHeader, CardLabel, CardTitle } from '../../bootstrap/Card';
import { useReactToPrint } from 'react-to-print';
import Icon from '../../icon/Icon';
import Input from '../../bootstrap/forms/Input';
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from '../../bootstrap/Dropdown';
import FormGroup from '../../bootstrap/forms/FormGroup';
import Checks, { ChecksGroup } from '../../bootstrap/forms/Checks';
import { convertJsonToPdf, downloadExcel, downloadFile } from '../../../services/ExportService';
import AlertService from '../../../services/AlertService';
import OffCanvas, { OffCanvasBody, OffCanvasHeader, OffCanvasTitle } from '../../bootstrap/OffCanvas';
import { useNavigate } from 'react-router-dom';
import Textarea from '../../bootstrap/forms/Textarea';
import PageWrapper from '../../../layout/PageWrapper/PageWrapper';
import OpenCardComponent from '../../../common/components/OpenCardComponent';
import { addStudentTransportDetails, deleteStudentDetails, getAllStudent, getProfileForStudent, getStudentDetails, getStudentDetailsByDepartmentId, getStudentTransportDetails } from '../../../services/student.service';
import { getColumnsForDataTable, getGraduationType, getSectionDetails, pictNotLoading, profilePic, updateColumnsForDataTable, updateFilter } from '../../../services/common.service';
import AuthContext from '../../../contexts/authContext';
import { getLicenseKey } from '../../../services/application.settings';
import { getTransportAreaMaster } from '../../../services/transport.service';
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from '../../../services/master.service';
import { addStudentScholarshipDetails, getScholarshipDetailsByStudentDetailsId, getScholarshipType } from '../../../services/scholarship.service';
import Badge from '../../bootstrap/Badge';
import { showLoader, TableLoader } from '../../../services/loader.services';
import Select from '../../bootstrap/forms/Select';
import NoDataMsg from '../../../common/components/NoDataMsg';
import dayjs from 'dayjs';
import SearchableSelect from '../../../common/components/SearchableSelect';
import { getProfileForStaff } from '../../../services/staff.service';

const StudentList = () => {

    useEffect(() => {

        getScholarshipTypeList();
        getTransportAreaMasterList();
        if (!getAccess) {
            getStaffProfile(userAccountId)
        }
        else {
            getGraduationList();
            getDepartment();
            getSectionList();
            getAllStudentDetails();
        }
        console.log(getAccess);

    }, [])

    const { userAccountId, userTypeId, addAccess, editAccess, deleteAccess, getAccess } = useContext(AuthContext);
    const [departmentId, setDepartmentId] = useState<any>(Number)
    const [courseId, setCourseId] = useState<any>(Number)
    const [batchMasterId, setBatchMasterId] = useState<any>(Number)
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>(Number)
    const [sectionDetailsId, setSectionDetailsId] = useState<any>(Number)
    const [graduationTypeId, setGraduationTypeId] = useState<any>(Number)
    const [transportAreaMasterId, setTransportAreaMasterId] = useState<any>(Number)

    const [studentDetailsId, setStudentDetailsId] = useState<any>(Number)
    const [columnVisibilityData, setColumnVisibilityData] = useState<any>([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [departmentData, setDepartmentData] = useState<any>([])
    const [transportAreaMasterData, setTransportAreaMasterData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentDetailsData, setStudentDetailsData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState<any>([])
    const [allStudentData, setAllStudentData] = useState<any>([])
    const [scholarshipTypeData, setScholarshipTypeData] = useState<any>([])
    const [studentScholarshipData, setStudentScholarshipData] = useState([])
    const [isOpenListCard, setIsOpenListCard] = useState(true);
    const [dataSuccess, setDataSuccess] = useState(false)
    const { themeStatus, darkModeStatus } = useDarkMode();
    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);
    const [addUpdateTransportCanvas, setUpdateTransportCanvas] = useState(false)
    const [addAssignScholarshipCanvas, setAssignScholarshipCanvas] = useState(false)
    const [deleteStudentListCanvas, setDeleteStudentListCanvas] = useState(false)
    const [isRemove, setIsRemove] = useState(false)
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [scholarshipTypeIdArray, setStudentScholarshipIdArray] = useState<any>([])
    const [filteredDataToRefresh, setFilteredDataToRefresh] = useState<any>([])
    const [studentTransportDetailsData, setStudentTransportDetailsData] = useState<any>([])
    const navigate = useNavigate();

    const [isStudentOrClassWise, setIsStudentOrClassWise] = useState(true)
    const [isLoader, setIsLoader] = useState(false)
    const [ifRemarksShow, setIfRemarksShow] = useState(false)
    const [noDataMsg, setNoDataMsg] = useState('')
    const [isDeleteOpen, setIsDeleteOpen] = useState(false)
    const [dataStatus, setDataStatus] = useState(false)

    const updateStudentTransportAreaForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            transportAreaMasterId: '',
            modeOfWays: "",
            isRemove: 0,
            remarks: ""
        },
        validate: (values) => {
            const errors: {
                transportAreaMasterId?: string;
                modeOfWays?: string;
                remarks?: string;
            } = {};
            if (!transportAreaMasterId) {
                errors.transportAreaMasterId = 'Required';
            }
            if (!values.modeOfWays) {
                errors.modeOfWays = 'Required';
            }
            if (ifRemarksShow) {
                if (!values.remarks) {
                    errors.remarks = 'Required';
                }
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: (values) => { addStudentTransportDetailsSubmit() },
    });

    const deleteStudentForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            remarks: '',
            lastDateOfAttendance: '',
        },
        validate: (values) => {
            const errors: {
                lastDateOfAttendance?: string;
            } = {};
            if (!values.lastDateOfAttendance) {
                errors.lastDateOfAttendance = 'Required';
            }
            return errors;
        },
        //validateOnChange: false,
        onSubmit: () => { setIsDeleteOpen(!isDeleteOpen) },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },
        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStudent = i
            getStudentProfile(selectedStudent.studentDetailsId)
            setStudentDetailsId({ value: selectedStudent.studentDetailsId, label: selectedStudent.studentNameAndAdmissionNo })
        }
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let courseId = e
        setCourseId(courseId)
        if (courseId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setStudentDetailsId('')
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        setSectionDetailsId('')
        setStudentDetailsId('')
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        setStudentDetailsId('')
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const selectStudent = (e: any) => {
        setDataSuccess(false)
        setIsOpenListCard(true)
        let studentDetailsId = e
        setStudentDetailsId(studentDetailsId)
    }

    function viewParticularStudent() {
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        if (studentDetailsId?.value != undefined) {
            getParticularStudent(studentDetailsId?.value);
        }
    }

    function viewStudentClassWiseList() {
        setDataSuccess(false)
        setIsLoader(true);
        columnVisibilityForm.resetForm();
        getStudentList(departmentId?.value, courseId?.value, batchMasterId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    function updateStudentTransport() {
        setUpdateTransportCanvas(true)
        getStudentTransport(studentDetailsId?.value)
    }

    function assignScholarshipForStudent() {
        setAssignScholarshipCanvas(true)
        getScholarshipDetailsByStudentDetails(studentDetailsId?.value);
    }

    function removeTransport(e: any) {
        setIsRemove(e.target.checked)
    }

    function selectTransportArea(e: any) {
        let transportAreaMasterId = e
        setTransportAreaMasterId(transportAreaMasterId)
    }

    function selectScholarship(e: any, scholarshipTypeId: any) {
        const newData: any = [];
        scholarshipTypeData.forEach((item: any, index: any) => {
            if (item['scholarshipTypeId'] == scholarshipTypeId) {
                item['isChecked'] = e.target.checked
            }
            newData.push(item)
        });
        setFilteredDataToRefresh(newData)
        if (e.target.checked == true) {
            scholarshipTypeIdArray.push(scholarshipTypeId);
        }
        else if (e.target.checked == false) {
            let unCheckArrayId = scholarshipTypeIdArray.indexOf(Number(scholarshipTypeId))

            scholarshipTypeIdArray.splice(unCheckArrayId, 1);
            const newData: any = [];
            scholarshipTypeData.forEach((item: any, index: any) => {
                if (item['scholarshipTypeId'] == scholarshipTypeId) {
                    item['isChecked'] = e.target.checked
                }
                newData.push(item)
            });
        }
    }

    function setAddStudentScholarship() {
        return {
            studentDetailsId: studentDetailsId?.value,
            licenseKey: getLicenseKey,
            scholarshipTypeId: scholarshipTypeIdArray,
            assignedBy: userAccountId,
        }
    }

    function setStudentTransportDetails() {
        return {
            studentDetailsId: studentDetailsId?.value,
            transportAreaMasterId: transportAreaMasterId?.value,
            modeOfWays: updateStudentTransportAreaForm.values.modeOfWays,
            remarks: updateStudentTransportAreaForm.values.remarks,
            isRemove: isRemove ? 1 : 0,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        }
    }

    function setDeleteStudentDetails() {
        return {
            studentDetailsId: studentDetailsId?.value,
            remarks: deleteStudentForm.values.remarks,
            lastDateOfAttendance: deleteStudentForm.values.lastDateOfAttendance,
            userAccountId: userAccountId,
            licenseKey: getLicenseKey
        }
    }

    function getParticularStudent(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setIsLoader(false);
                        getColumnsForTable('getStudentDetails', 'get');
                        setStudentDetailsData(data);
                        setDataSuccess(true)
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getStudentDetails', 'get');
                    setStudentDetailsData([]);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                    setDataSuccess(false)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentProfile(studentDetailsId: number) {
        getProfileForStudent(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentProfile;
                    if (data != undefined) {
                        setStudentProfileData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setStudentProfileData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentList(departmentId: number, courseId: number, batchMasterId: number, semesterDetailsId: number, sectionDetailsId: number) {
        getStudentDetails(departmentId, courseId, batchMasterId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        getColumnsForTable('getStudentDetails', 'get');
                        setStudentDetailsData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataSuccess(true)
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                        setDataSuccess(false)
                        setIsOpenListCard(true);
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    getColumnsForTable('getStudentDetails', 'get');
                    setStudentDetailsData([]);
                    setNoDataMsg(response.data.message)
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true)
                    setIsOpenListCard(false);
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataSuccess(false)
                    setIsOpenListCard(true);
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
                setDataSuccess(false)
            }
        )
    }

    function getStudentDetailsByDepartment(departmentId: any) {
        setDataStatus(false)
        getStudentDetailsByDepartmentId(departmentId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsByDepartmentId;
                    if (data != undefined) {
                        getColumnsForTable('getStudentDetails', 'get');
                        setStudentDetailsData(data);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                        setDataStatus(true)
                    } else {
                        toasts("Undefined Data", "Error")
                        setDataStatus(true)
                    }
                }
                else if (response.data.success === false) {
                    getColumnsForTable('getStudentDetails', 'get');
                    setStudentDetailsData([]);
                    setNoDataMsg(response.data.message)
                    setDataStatus(true)
                } else {
                    setIsLoader(false);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                    setDataStatus(true)
                }
            }, error => {
                toasts(error, "Error")
                setDataStatus(true)
            }
        )
    }

    function getStaffProfile(userAccountId: number) {
        getProfileForStaff(userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.profileForStaff;
                    if (data != undefined) {
                        getStudentDetailsByDepartment(data[0].departmentId)
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getTransportAreaMasterList() {
        getTransportAreaMaster(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.transportAreaMaster;
                    if (data != undefined) {
                        setTransportAreaMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setTransportAreaMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: number, graduationTypeId: number) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: number, graduationTypeId: number) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScholarshipTypeList() {
        getScholarshipType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scholarshipType;
                    if (data != undefined) {
                        setScholarshipTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setScholarshipTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getScholarshipDetailsByStudentDetails(studentDetailsId: any) {
        getScholarshipDetailsByStudentDetailsId(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.scholarshipDetailsByStudentDetailsId;
                    if (data != undefined) {
                        setStudentScholarshipData(data);
                        data.forEach((scholarshipType: any) => {
                            scholarshipTypeData.forEach((item: any) => {
                                if (item.scholarshipTypeId == scholarshipType.scholarshipTypeId) {
                                    item['isChecked'] = true;
                                    item['dateStamp'] = scholarshipType.dateStamp;
                                    scholarshipTypeIdArray.push(item['scholarshipTypeId']);
                                }
                            })
                        })
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getAllStudentDetails() {
        getAllStudent(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetails;
                    if (data != undefined) {
                        setAllStudentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAllStudentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentTransport(studentDetailsId: any) {
        getStudentTransportDetails(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTransportDetails[0];
                    if (data != undefined) {
                        setTransportAreaMasterId({ value: data['transportAreaMasterId'], label: data.areaName })
                        updateStudentTransportAreaForm.setValues({
                            studentDetailsId: studentDetailsId,
                            transportAreaMasterId: data['transportAreaMasterId'],
                            modeOfWays: data['modeOfWays'],
                            isRemove: 0,
                            remarks: '',
                        })
                        setIfRemarksShow(true)
                        setStudentTransportDetailsData(data)

                    } else {
                        //toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setStudentTransportDetailsData([])
                    setIfRemarksShow(false)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function addStudentScholarshipSubmit() {
        showLoader(true)
        if (scholarshipTypeIdArray.length > 0) {
            let studentScholarshipPostData = setAddStudentScholarship();
            addStudentScholarshipDetails(studentScholarshipPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        closeAndReset();
                        getScholarshipDetailsByStudentDetails(studentDetailsId?.value)
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (scholarshipTypeIdArray.length == 0) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function addStudentTransportDetailsSubmit() {
        showLoader(true)
        if (updateStudentTransportAreaForm.isValid) {
            let addStudentTransportDetailsPostData = setStudentTransportDetails();
            addStudentTransportDetails(addStudentTransportDetailsPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        getStudentTransport(studentDetailsId?.value)
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentTransportAreaForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function deleteStudentSubmit() {
        showLoader(true)
        if (deleteStudentForm.isValid) {
            let deleteStudentPostData = setDeleteStudentDetails();
            deleteStudentDetails(deleteStudentPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        setDataSuccess(false)
                        setIsOpenListCard(true);
                        selectStudentCardForDelete();
                        getAllStudentDetails();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false)
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        showLoader(false)
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }
                , (error) => {
                    showLoader(false)
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (deleteStudentForm.isValid == false) {
            showLoader(false)
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentDetails', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    // Print
    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    function closeAndReset() {
        deleteStudentForm.resetForm();
        updateStudentTransportAreaForm.resetForm();
        setFilteredDataToRefresh([])
        setIsRemove(false);
        setTransportAreaMasterId('')
        setIsDeleteOpen(false);
        const newData: any = [];
        scholarshipTypeData.forEach((item: any, index: any) => {
            item['isChecked'] = false
            newData.push(item)
        });
        setFilteredDataToRefresh(newData)
        setDeleteStudentListCanvas(false)
        setStudentScholarshipIdArray([])
    }

    function selectStudentCard() {
        setIsStudentOrClassWise(!isStudentOrClassWise)
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
    }

    function selectStudentCardForDelete() {
        setDataSuccess(false)
        setDepartmentId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSectionDetailsId('')
        setGraduationTypeId('')
        setStudentDetailsId('')
    }

    return (
        <PageWrapper title='Student List'>
            <Page container='fluid'>
                {getAccess == true ?
                    <>
                        <Collapse isOpen={isOpenListCard}>
                            <Card>
                                <CardHeader borderSize={1}>

                                    <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                        <CardTitle tag='div' className='h5'>Student List</CardTitle>
                                    </CardLabel>
                                    <CardActions >
                                        <Button
                                            icon='PersonSearch'
                                            color='info'
                                            isLight={isStudentOrClassWise ? false : true}
                                            onClick={selectStudentCard}>
                                            Student
                                        </Button>

                                        <Button
                                            icon='PeopleAlt'
                                            color='info'
                                            isLight={isStudentOrClassWise ? true : false}
                                            onClick={selectStudentCard}>
                                            Class Wise
                                        </Button>
                                        <Button className='float-end'
                                            icon='Add'
                                            color='primary'
                                            isLight
                                            tag='a'
                                            to={`../academic/addStudent`}>
                                            Add New
                                        </Button>
                                    </CardActions>
                                </CardHeader>
                                {isStudentOrClassWise ?
                                    <CardBody>
                                        <div className='row'>
                                            <div className="col-4">
                                                <FormGroup id='studentDetailsId' label='Student' isFloating>
                                                    <SearchableSelect isFloating
                                                        ariaLabel='Student'
                                                        onChange={selectStudent}
                                                        value={studentDetailsId}
                                                        list={allStudentData.map((data: any) => (
                                                            { value: data.studentDetailsId, label: data.studentNameAndAdmissionNo }
                                                        ))}
                                                        required />
                                                </FormGroup>
                                            </div>
                                            <div className="col-3 align-self-center">
                                                <Button icon='ArrowDownward' color='primary' onClick={viewParticularStudent} isDisable={studentDetailsId?.value == undefined}>View</Button>
                                            </div>
                                        </div>
                                    </CardBody> :
                                    <CardBody>
                                        <div className='row'>
                                            <div className='col-3'>
                                                <FormGroup id='departmentId' label='Department' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment} value={departmentId}
                                                        list={departmentData.map((data: any) => (
                                                            { value: data.departmentId, label: data.departmentName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div>
                                            {departmentId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType}
                                                        value={graduationTypeId} list={graduationTypeData.map((data: any) => (
                                                            { value: data.graduationTypeId, label: data.graduationType }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {graduationTypeId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='courseId' label='Course' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId}
                                                        list={courseData.map((data: any) => (
                                                            { value: data.courseId, label: data.courseName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {courseId?.value != undefined ? <div className='col-3'>
                                                <FormGroup id='batchMasterId' label='Batch' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId}
                                                        list={batchMasterData.map((data: any) => (
                                                            { value: data.batchMasterId, label: data.batchName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                                <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                                        list={semesterDetailsData.map((data: any) => (
                                                            { value: data.semesterDetailsId, label: data.semesterNumber }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                                <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                                    <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                                        list={sectionDetailsData.map((data: any) => (
                                                            { value: data.sectionDetailsId, label: data.sectionName }
                                                        ))}
                                                    />
                                                </FormGroup>
                                            </div> : null}

                                            {sectionDetailsId?.value != undefined ? <div className="col-3 mt-4">
                                                <Button icon='ArrowDownward' color='primary' isDisable={sectionDetailsId?.value == undefined} onClick={viewStudentClassWiseList}>View</Button>
                                            </div> : null}
                                        </div>
                                    </CardBody>}
                            </Card>
                        </Collapse>

                        <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                        {dataSuccess ?
                            <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                                <CardHeader borderSize={1}>
                                    <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                        <CardTitle tag='div' className='h5'>Student List</CardTitle>
                                    </CardLabel>&nbsp;&nbsp;

                                    <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                        <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                        <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                    </ButtonGroup>

                                    <CardActions className="d-print-none">
                                        <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                            <DropdownToggle>
                                                <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                                <DropdownItem>
                                                    <div className='container py-2'>
                                                        <form className='row g-3'>
                                                            <div className='col-12'>
                                                                <FormGroup>
                                                                    <h6>Select All</h6>
                                                                    <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                                </FormGroup>
                                                            </div>
                                                            <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                                <FormGroup>
                                                                    <h6>Columns</h6>
                                                                    <ChecksGroup>
                                                                        {allColumnsData.map((i: any) => (
                                                                            <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                        ))}
                                                                    </ChecksGroup>
                                                                </FormGroup>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                        <Dropdown isButtonGroup>
                                            <DropdownToggle>
                                                <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                            </DropdownToggle>
                                            <DropdownMenu isAlignmentEnd>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student List", studentDetailsData, columnVisibilityData)}> Excel </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentDetailsData, columnVisibilityData, "Student List")}> PDF
                                                    </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student List", studentDetailsData, columnVisibilityData)}> CSV </Button>
                                                </DropdownItem>
                                                <DropdownItem>
                                                    <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                                </DropdownItem>
                                            </DropdownMenu>
                                        </Dropdown>
                                    </CardActions>
                                </CardHeader>

                                <CardBody className='table-responsive' isScrollable>
                                    <table className='table table-modern table-hover text-nowrap'>
                                        <thead>
                                            <tr>
                                                <th scope='col' onClick={() => requestSort('sno')}
                                                    className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                    <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                                </th>
                                                {columnVisibilityData.map((column: any) => (
                                                    <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                        className='cursor-pointer text-decoration-underline'>
                                                        {column.columnName}
                                                        <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                    </th>
                                                ))}
                                                <td className="d-print-none" />
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}

                                                <td className='d-print-none' onClick={() => { onActivate(rowData) }}>
                                                    <Dropdown isButtonGroup>
                                                        <DropdownToggle>
                                                            <Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
                                                        </DropdownToggle>
                                                        {userTypeId == 6 ?
                                                            <DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>
                                                                <DropdownItem>
                                                                    <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId?.value}`)}>Student Profile</Button>
                                                                </DropdownItem>
                                                                {editAccess ?
                                                                    <DropdownItem>
                                                                        <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
                                                                    </DropdownItem>
                                                                    : null}
                                                            </DropdownMenu>
                                                            :
                                                            <DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>

                                                                <DropdownItem>
                                                                    <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId?.value}`)}>Student Profile</Button>
                                                                </DropdownItem>
                                                                {editAccess ?
                                                                    <DropdownItem>
                                                                        <Button icon="Update" isLight color="success" onClick={() => navigate(`../academic/updateStudent/${studentDetailsId?.value}`)}>Update student Details</Button>
                                                                    </DropdownItem>
                                                                    : null}
                                                                {editAccess ?
                                                                    <DropdownItem>
                                                                        <Button icon="DirectionsBus" isLight color="primary" onClick={updateStudentTransport}>Update Transport
                                                                        </Button>
                                                                    </DropdownItem>
                                                                    : null}
                                                                {editAccess ?
                                                                    <DropdownItem>
                                                                        <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
                                                                    </DropdownItem>
                                                                    : null}
                                                                <DropdownItem>
                                                                    <Button icon="EditLocation" isLight color="primary" onClick={() => navigate(`../academic/studentCertificateTracker/${studentDetailsId?.value}`)}>Certificate Tracker</Button>
                                                                </DropdownItem>
                                                                <DropdownItem>
                                                                    <Button icon="UploadFile" isLight color="primary" onClick={assignScholarshipForStudent}>Assign Scholarship</Button>
                                                                </DropdownItem>
                                                                <DropdownItem>
                                                                    <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType1/${studentDetailsId?.value}`)}>Bonafide Certificate 1 </Button>
                                                                </DropdownItem>
                                                                <DropdownItem>
                                                                    <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType2/${studentDetailsId?.value}`)}>Bonafide Certificate 2 </Button>
                                                                </DropdownItem>
                                                                {editAccess ?
                                                                    <DropdownItem>
                                                                        <Button icon="Delete" isLight color="primary" onClick={() => { setDeleteStudentListCanvas(true) }}>Discontinue</Button>
                                                                    </DropdownItem>
                                                                    : null}
                                                            </DropdownMenu>
                                                        }
                                                    </Dropdown>
                                                </td>
                                            </tr>))}</> :
                                                <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                        </tbody>
                                    </table>
                                </CardBody>
                                <PaginationButtons
                                    className='d-print-none'
                                    data={items}
                                    label='items'
                                    setCurrentPage={setCurrentPage}
                                    currentPage={currentPage}
                                    perPage={perPage}
                                    setPerPage={setPerPage}
                                />
                            </Card> : null}
                    </>
                    :
                    <Card stretch data-tour='list' ref={componentRef} id='pdf'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                <CardTitle tag='div' className='h5'>Student List</CardTitle>
                            </CardLabel>&nbsp;&nbsp;

                            <ButtonGroup className="col-lg-3 d-print-none" color='primary'>
                                <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                            </ButtonGroup>

                            <CardActions className="d-print-none">
                                <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                    <DropdownToggle>
                                        <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                        <DropdownItem>
                                            <div className='container py-2'>
                                                <form className='row g-3'>
                                                    <div className='col-12'>
                                                        <FormGroup>
                                                            <h6>Select All</h6>
                                                            <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentDetails', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                        </FormGroup>
                                                    </div>
                                                    <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                        <FormGroup>
                                                            <h6>Columns</h6>
                                                            <ChecksGroup>
                                                                {allColumnsData.map((i: any) => (
                                                                    <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                ))}
                                                            </ChecksGroup>
                                                        </FormGroup>
                                                    </div>
                                                </form>
                                            </div>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                                <Dropdown isButtonGroup>
                                    <DropdownToggle>
                                        <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                    </DropdownToggle>
                                    <DropdownMenu isAlignmentEnd>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student List", studentDetailsData, columnVisibilityData)}> Excel </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentDetailsData, columnVisibilityData, "Student List")}> PDF
                                            </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student List", studentDetailsData, columnVisibilityData)}> CSV </Button>
                                        </DropdownItem>
                                        <DropdownItem>
                                            <Button color='primary' isLight icon='Print' onClick={handlePrint}> Print </Button>
                                        </DropdownItem>
                                    </DropdownMenu>
                                </Dropdown>
                            </CardActions>
                        </CardHeader>

                        <CardBody className='table-responsive' isScrollable>
                            {!dataStatus ?
                                <TableLoader /> :
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <td className="d-print-none" />
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}

                                            <td className='d-print-none' onClick={() => { onActivate(rowData) }}>
                                                <Dropdown isButtonGroup>
                                                    <DropdownToggle>
                                                        <Button className="text-nowrap" color='dark' isLight icon='MoreHoriz' aria-label='MoreHoriz'>More</Button>
                                                    </DropdownToggle>
                                                    {userTypeId == 6 ?
                                                        <DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>
                                                            <DropdownItem>
                                                                <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId?.value}`)}>Student Profile</Button>
                                                            </DropdownItem>
                                                            {editAccess ?
                                                                <DropdownItem>
                                                                    <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
                                                                </DropdownItem>
                                                                : null}
                                                        </DropdownMenu>
                                                        :
                                                        <DropdownMenu isAlignmentEnd className='card-body-scrollable' size='lg'>

                                                            <DropdownItem>
                                                                <Button icon="PersonPin" isLight color="info" onClick={() => navigate(`../academic/studentProfile/${studentDetailsId?.value}`)}>Student Profile</Button>
                                                            </DropdownItem>
                                                            {editAccess ?
                                                                <>
                                                                    <DropdownItem>
                                                                        <Button icon="Update" isLight color="success" onClick={() => navigate(`../academic/updateStudent/${studentDetailsId?.value}`)}>Update student Details</Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="DirectionsBus" isLight color="primary" onClick={updateStudentTransport}>Update Transport
                                                                        </Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="RequestQuote" isLight color="primary" onClick={() => navigate(`../general/termFeesCollection/${studentDetailsId?.value}`)}>Fee Details</Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="EditLocation" isLight color="primary" onClick={() => navigate(`../academic/studentCertificateTracker/${studentDetailsId?.value}`)}>Certificate Tracker</Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="UploadFile" isLight color="primary" onClick={assignScholarshipForStudent}>Assign Scholarship</Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType1/${studentDetailsId?.value}`)}>Bonafide Certificate 1 </Button>
                                                                    </DropdownItem>
                                                                    <DropdownItem>
                                                                        <Button icon="Description" isLight color="primary" onClick={() => navigate(`../academic/bonafideCertificateType2/${studentDetailsId?.value}`)}>Bonafide Certificate 2 </Button>
                                                                    </DropdownItem>

                                                                    <DropdownItem>
                                                                        <Button icon="Delete" isLight color="primary" onClick={() => { setDeleteStudentListCanvas(true) }}>Discontinue</Button>
                                                                    </DropdownItem>
                                                                </>
                                                                : null}
                                                        </DropdownMenu>
                                                    }
                                                </Dropdown>
                                            </td>
                                        </tr>))}</> :
                                            <NoDataMsg columnsCount={columnVisibilityData.length + 2} msg={noDataMsg} />}
                                    </tbody>
                                </table>
                            }
                        </CardBody>
                        <PaginationButtons
                            className='d-print-none'
                            data={items}
                            label='items'
                            setCurrentPage={setCurrentPage}
                            currentPage={currentPage}
                            perPage={perPage}
                            setPerPage={setPerPage}
                        />
                    </Card>
                }

                <OffCanvas
                    setOpen={setUpdateTransportCanvas}
                    isOpen={addUpdateTransportCanvas}
                    titleId='addUpdateTransportCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={updateStudentTransportAreaForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setUpdateTransportCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addUpdateTransportCanvas">Update Transport</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row">
                            {studentTransportDetailsData != '' ?
                                <div className='col-12'>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                        }-info-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0 ms-2'>
                                                    <Icon size='3x' icon='DirectionsBusFilled' color='primary' />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-4 text-dark mb-0'>
                                                        {studentTransportDetailsData.routeName != null ? studentTransportDetailsData.routeName : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />}
                                                    </div>
                                                    <div className='text-muted'>
                                                        (Point Number - {studentTransportDetailsData.routeNumber != null ? studentTransportDetailsData.routeNumber : <Icon icon='Remove' className='mb-0 text-dark fw-bold h2' />} )
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div> : null}
                            <div className="col-12">
                                <FormGroup id='transportAreaMasterId' label="Area of Student" >
                                    <SearchableSelect
                                        ariaLabel='Area of Student'
                                        placeholder="Select Area of Student"
                                        onChange={selectTransportArea}
                                        value={transportAreaMasterId}
                                        onBlur={updateStudentTransportAreaForm.handleBlur}
                                        isValid={updateStudentTransportAreaForm.isValid}
                                        isTouched={updateStudentTransportAreaForm.touched.transportAreaMasterId}
                                        invalidFeedback={updateStudentTransportAreaForm.errors.transportAreaMasterId}
                                        list={transportAreaMasterData.map((data: any) => (
                                            { value: data.transportAreaMasterId, label: data.areaName }
                                        ))}
                                    />
                                </FormGroup>
                            </div>

                            <div className="col-12 mt-3">
                                <FormGroup id='modeOfWays' label="Ways">
                                    <Select ariaLabel='Select Ways' placeholder="Select Ways"
                                        onChange={updateStudentTransportAreaForm.handleChange}
                                        value={updateStudentTransportAreaForm.values.modeOfWays}
                                        onBlur={updateStudentTransportAreaForm.handleBlur}
                                        isValid={updateStudentTransportAreaForm.isValid}
                                        isTouched={updateStudentTransportAreaForm.touched.modeOfWays}
                                        invalidFeedback={updateStudentTransportAreaForm.errors.modeOfWays}
                                    >
                                        <option value='1'>One Ways</option>
                                        <option value='2'>Two Ways</option>
                                    </Select>
                                </FormGroup>
                            </div>
                            {ifRemarksShow ?
                                <>
                                    <div className="col-12 mt-3">
                                        <FormGroup id='remarks' label="Remarks">
                                            <Textarea
                                                placeholder="Enter Remarks"
                                                onChange={updateStudentTransportAreaForm.handleChange}
                                                value={updateStudentTransportAreaForm.values.remarks}
                                                onBlur={updateStudentTransportAreaForm.handleBlur}
                                                isValid={updateStudentTransportAreaForm.isValid}
                                                isTouched={updateStudentTransportAreaForm.touched.remarks}
                                                invalidFeedback={updateStudentTransportAreaForm.errors.remarks}
                                            />
                                        </FormGroup>
                                    </div>
                                    <div className="fs-5 col-12 mt-4">
                                        <ButtonGroup>
                                            <Checks onChange={removeTransport} checked={isRemove} />
                                            <h6 className="fs-5 mt-1">
                                                <Badge isLight color='danger'>
                                                    Remove
                                                </Badge>
                                            </h6>
                                        </ButtonGroup>

                                    </div>
                                </>
                                : null}
                        </div>
                        <div className='row m-0'>
                            <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                                <Button
                                    color='info'
                                    icon='Save'
                                    className='w-100' type="submit" isDisable={!updateStudentTransportAreaForm.isValid && !!updateStudentTransportAreaForm.submitCount}>
                                    Save
                                </Button>
                            </div>
                        </div>
                    </OffCanvasBody>
                </OffCanvas>

                <OffCanvas
                    setOpen={setAssignScholarshipCanvas}
                    isOpen={addAssignScholarshipCanvas}
                    titleId='addAssignScholarshipCanvas'
                    isBodyScroll
                    placement='end'>
                    <OffCanvasHeader setOpen={setAssignScholarshipCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="addAssignScholarshipCanvas">Assign Scholarship</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className='row'>
                            {scholarshipTypeData != '' ?
                                <>
                                    {scholarshipTypeData.map((i: any) => (
                                        <div className='col-12' key={i.scholarshipTypeId}>
                                            <ChecksGroup className='fs-5'>
                                                <>
                                                    <Checks label={i.scholarshipType}
                                                        checked={i.isChecked} onChange={(e) => selectScholarship(e, i.scholarshipTypeId)}
                                                    />
                                                    {i.isChecked ? <div className='ms-4 text-muted'>
                                                        <small>
                                                            Assigned:{' '}
                                                            <span className='text-info fw-bold'>
                                                                {dayjs(i.dateStamp).format('D MMMM, YYYY h:mm A')}
                                                            </span>
                                                        </small>

                                                    </div> : null} <br />
                                                </>
                                            </ChecksGroup>
                                        </div>
                                    ))}
                                </> :
                                <p className="text-center">No Data</p>}
                        </div>
                    </OffCanvasBody>
                    <div className='row m-0'>
                        <div className='col-12 p-3 position-absolute top-95 start-50 translate-middle'>
                            <Button
                                color='info'
                                icon='Save'
                                className='w-100' type="submit" onClick={() => { addStudentScholarshipSubmit() }} isDisable={scholarshipTypeIdArray.length == 0}>
                                Save
                            </Button>
                        </div>
                    </div>
                </OffCanvas>

                <OffCanvas
                    setOpen={setDeleteStudentListCanvas}
                    isOpen={deleteStudentListCanvas}
                    titleId='deleteStudentListCanvas'
                    isBodyScroll
                    placement='end'
                    tag="form" noValidate onSubmit={deleteStudentForm.handleSubmit}>
                    <OffCanvasHeader setOpen={setDeleteStudentListCanvas} onClick={closeAndReset}>
                        <OffCanvasTitle id="deleteStudentListCanvas">Discontinue Student</OffCanvasTitle>
                    </OffCanvasHeader>
                    <OffCanvasBody>
                        <div className="row">
                            {studentProfileData.map((student: any) => (
                                <div className='col-12' key={student.studentDetailsId}>
                                    <Card className={`bg-l${darkModeStatus ? 'o25' : '25'
                                        }-info bg-l${darkModeStatus ? 'o50' : '10'
                                        }-info-hover transition-base rounded-2 mb-4`}
                                        shadow='sm'>
                                        <CardBody>
                                            <div className='d-flex align-items-center pb-3'>
                                                <div className='flex-shrink-0 ms-2'>
                                                    <img style={{ borderRadius: '50%' }} src={student.profilePath != null ? student.profilePath : profilePic(student.genderId)} height={65} width={65} onError={(e: any) => pictNotLoading(e, student.genderId)} />
                                                </div>
                                                <div className='flex-grow-1 ms-3'>
                                                    <div className='fw-bold fs-4 text-dark mb-0'>
                                                        {student.studentName}
                                                    </div>
                                                    <div className='text-muted'>
                                                        ( {student.admissionNo} )
                                                    </div>
                                                </div>
                                            </div>
                                        </CardBody>
                                    </Card>
                                </div>
                            ))}
                            <div className="col-12 mt-3">
                                <FormGroup id='remarks' label="Remarks" >
                                    <Textarea
                                        placeholder="Enter Remarks"
                                        onChange={deleteStudentForm.handleChange}
                                        value={deleteStudentForm.values.remarks}
                                        onBlur={deleteStudentForm.handleBlur}
                                    // isValid={deleteStudentForm.isValid}
                                    // isTouched={deleteStudentForm.touched.remarks}
                                    // invalidFeedback={deleteStudentForm.errors.remarks}
                                    />
                                </FormGroup>
                            </div>
                            <div className="col-12 mt-3">
                                <FormGroup id='lastDateOfAttendance' label="Last Date Of Attendance" >
                                    <Input
                                        type='date'
                                        placeholder="Enter Last Date Of Attendance"
                                        onChange={deleteStudentForm.handleChange}
                                        value={deleteStudentForm.values.lastDateOfAttendance}
                                        onBlur={deleteStudentForm.handleBlur}
                                        isValid={deleteStudentForm.isValid}
                                        isTouched={deleteStudentForm.touched.lastDateOfAttendance}
                                        invalidFeedback={deleteStudentForm.errors.lastDateOfAttendance}
                                    />
                                </FormGroup>
                            </div>
                        </div>
                    </OffCanvasBody>
                    <div className='row'>
                        <div className="d-flex justify-content-center position-absolute top-65 start-50 translate-middle">
                            <Collapse isOpen={isDeleteOpen} className="dropdown-menu">
                                <div className="row g-4 mt-2">
                                    <div className="col-12 d-flex justify-content-center">
                                        <h5><strong>Are you sure want to discontinue ?</strong></h5>
                                    </div>
                                    <div className='col-10'>
                                        <div className='d-flex gap-2 justify-content-end'>
                                            <Button color='danger' size={'sm'} onClick={deleteStudentSubmit}>
                                                Yes
                                            </Button>

                                            <Button color='info' size={'sm'} onClick={() => setIsDeleteOpen(!isDeleteOpen)}>
                                                No
                                            </Button>
                                        </div>
                                    </div>
                                    <div className='col-10'></div>
                                </div>
                            </Collapse>
                        </div>
                        <div className='col-12 position-absolute bottom-0 end-0 p-3'>
                            <Button
                                color='danger'
                                icon='Delete'
                                className='w-100' type="submit" isDisable={!deleteStudentForm.isValid && !!deleteStudentForm.submitCount}>
                                Discontinue
                            </Button>
                        </div>
                    </div>
                </OffCanvas>
            </Page>

            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />

        </PageWrapper>
    );
}

export default StudentList