import React, { useContext, useEffect, useState } from 'react';
import Card, {
    CardBody,
    CardHeader,
    CardLabel,
    CardTitle,
} from '../../../../components/bootstrap/Card';
import Icon from '../../../../components/icon/Icon';
import useDarkMode from '../../../../hooks/useDarkMode';
import { toasts } from '../../../../services/toast.service';
import AuthContext from '../../../../contexts/authContext';
import { getDashboardDataByUserTypeId } from '../../../../services/dashboard.service';
import { TableLoader } from '../../../../services/loader.services';
import classNames from 'classnames';

const AttendanceDetailsForHod = () => {
    const { userAccountId, userData, userTypeId } = useContext(AuthContext);

    useEffect(() => {
        if (userTypeId != '' && userAccountId != '') {
            getDashboardDataByUserTypeIdList(userTypeId, userAccountId);
        }
    }, []);


    const { themeStatus, darkModeStatus } = useDarkMode();
    const [dataStatus, setDataStatus] = useState(false);
    const [isLoader, setIsLoader] = useState(false);
    const [dashboardDataByUserTypeData, setDashboardDataByUserTypeData] = useState<any>([])


    function getDashboardDataByUserTypeIdList(userTypeId: any, userAccountId: any) {
        setDataStatus(false);
        getDashboardDataByUserTypeId(userTypeId, userAccountId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.dashboardDataByUserTypeId[0];
                    if (data != undefined) {
                        setDataStatus(true);
                        setDashboardDataByUserTypeData(data);
                    }
                    else {
                        setDataStatus(true);
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    setDataStatus(true);
                    // toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setDataStatus(true);
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                setDataStatus(true);
                toasts(error, 'Error');
            },
        );
    }

    return (
        // <Card stretch className='rounded-1 border border-light' shadow={'none'}>
		// 	<CardHeader borderSize={1}>
		// 		<CardLabel icon='Collections' iconColor='info'>
		// 			<CardTitle tag='div' className='h5'>
		// 				Attendance Details
		// 			</CardTitle>
		// 		</CardLabel>
		// 	</CardHeader>
		// 	<CardBody >
        <>
            {!dataStatus ?
                <TableLoader /> :
                // <div className='row  align-items-center'>
                //     <div className='col-md-12'>
                //         <Card
                //             className={`bg-l${darkModeStatus ? 'o25' : '25'
                //                 }-warning bg-l${darkModeStatus ? 'o50' : '10'
                //                 }-warning-hover transition-base rounded-2 `}
                //             shadow='sm'>
                //             <CardBody>
                //                 <div
                //                     className='d-flex align-items-center pb-3 mt-3'
                //                     style={{ height: '22px' }}>
                //                     <div
                //                         className='ratio ratio-1x1 me-3'
                //                         style={{ width: 40 }}>
                //                         <div
                //                             className={classNames(
                //                                 'rounded-5',
                //                                 'd-flex align-items-center justify-content-center',
                //                                 'bg-warning',
                //                             )}>
                //                             <span className='avatar-1 fs-3 text-white fw-bold'>
                //                                 T
                //                             </span>
                //                         </div>
                //                     </div>
                //                     <div className='flex-grow-1 ms-3'>
                //                         <div className='fw-bold fs-4 text-warning mb-0'>
                //                             {dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0}
                //                         </div>
                //                         <div className='text-muted'>
                //                             <b>Total Student</b>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </CardBody>
                //         </Card>
                //     </div>
                //     <div className='col-md-12'>
                //         <Card
                //             className={`bg-l${darkModeStatus ? 'o25' : '25'
                //                 }-danger bg-l${darkModeStatus ? 'o50' : '10'
                //                 }-danger-hover transition-base rounded-2 `}
                //             shadow='sm'>
                //             <CardBody>
                //                 <div
                //                     className='d-flex align-items-center pb-3 mt-3'
                //                     style={{ height: '22px' }}>
                //                     <div
                //                         className='ratio ratio-1x1 me-3'
                //                         style={{ width: 40 }}>
                //                         <div
                //                             className={classNames(
                //                                 'rounded-5',
                //                                 'd-flex align-items-center justify-content-center',
                //                                 'bg-danger',
                //                             )}>
                //                             <span className='avatar-1 fs-3 text-white fw-bold'>
                //                                 A
                //                             </span>
                //                         </div>
                //                     </div>
                //                     <div className='flex-grow-1 ms-3'>
                //                         <div className='fw-bold fs-4 text-danger mb-0'>
                //                             {dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}
                //                         </div>
                //                         <div className='text-muted'>
                //                             <b> Absent Student</b>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </CardBody>
                //         </Card>
                //     </div>
                //     <div className='col-md-12'>
                //         <Card
                //             className={`bg-l${darkModeStatus ? 'o25' : '25'
                //                 }-success bg-l${darkModeStatus ? 'o50' : '10'
                //                 }-success-hover transition-base rounded-2 `}
                //             shadow='sm'>
                //             <CardBody>
                //                 <div
                //                     className='d-flex align-items-center pb-3 mt-3'
                //                     style={{ height: '22px' }}>
                //                     <div
                //                         className='ratio ratio-1x1 me-3'
                //                         style={{ width: 40 }}>
                //                         <div
                //                             className={classNames(
                //                                 'rounded-5',
                //                                 'd-flex align-items-center justify-content-center',
                //                                 'bg-success',
                //                             )}>
                //                             <span className='avatar-1 fs-3 text-white fw-bold'>
                //                                 T
                //                             </span>
                //                         </div>
                //                     </div>
                //                     <div className='flex-grow-1 ms-3'>
                //                         <div className='fw-bold fs-4 text-success mb-0'>
                //                             {dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0}
                //                         </div>
                //                         <div className='text-muted'>
                //                             <b>Total Staff</b>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </CardBody>
                //         </Card>
                //     </div>
                //     <div className='col-md-12'>
                //         <Card
                //             className={`bg-l${darkModeStatus ? 'o25' : '25'
                //                 }-primary bg-l${darkModeStatus ? 'o50' : '10'
                //                 }-primary-hover transition-base rounded-2 `}
                //             shadow='sm'>
                //             <CardBody>
                //                 <div
                //                     className='d-flex align-items-center pb-3 mt-3'
                //                     style={{ height: '22px' }}>
                //                     <div
                //                         className='ratio ratio-1x1 me-3'
                //                         style={{ width: 40 }}>
                //                         <div
                //                             className={classNames(
                //                                 'rounded-5',
                //                                 'd-flex align-items-center justify-content-center',
                //                                 'bg-primary',
                //                             )}>
                //                             <span className='avatar-1 fs-3 text-white fw-bold'>
                //                                 A
                //                             </span>
                //                         </div>
                //                     </div>
                //                     <div className='flex-grow-1 ms-3'>
                //                         <div className='fw-bold fs-4 text-primary mb-0'>
                //                             {dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}
                //                         </div>
                //                         <div className='text-muted'>
                //                             <b> Absent Staff</b>
                //                         </div>
                //                     </div>
                //                 </div>
                //             </CardBody>
                //         </Card>
                //     </div>
                // </div>
                <div className='row  align-items-center'>
					<div className='col-12'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStudentCount != undefined ? dashboardDataByUserTypeData.getTotalStudentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Student </div>
									</div>
									<div className='col-2'>
										<Icon icon='PeopleOutline' size='3x' color='info' />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-12'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStudentAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStudentAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Student</div>
									</div>
									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-12'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5 text-info'>{dashboardDataByUserTypeData.getTotalStaffCount != undefined ? dashboardDataByUserTypeData.getTotalStaffCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Total Staff</div>
									</div>

									<div className='col-2'>
										<Icon icon='PeopleOutline' size='3x' color='info'/>
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
					<div className='col-12'>
						<Card stretch className='border border-light rounded-1'>
							<CardBody>
								<div className="row">
									<div className='col-9'>
										<CardLabel>
											<CardTitle tag='div' className='h5' style={{ color: '#cc3300' }}>{dashboardDataByUserTypeData.getTodayStaffAbsentCount != undefined ? dashboardDataByUserTypeData.getTodayStaffAbsentCount : 0}</CardTitle>
										</CardLabel>
										<div className='fw-bold fs-6 mb-0 text-muted'>Absent Staff</div>
									</div>

									<div className='col-2'>
										<Icon icon='PersonOff' size='3x' style={{ color: '#cc3300' }} />
									</div>
								</div>
							</CardBody>
						</Card>
					</div>
				</div>
            }
            </>
        // </CardBody>
        // </Card>
    );
};

export default AttendanceDetailsForHod;
