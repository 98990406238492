import { useContext, useEffect, useRef, useState } from "react"
import useSortableData from "../../../../../hooks/useSortableData"
import PaginationButtons, { dataPagination } from "../../../../PaginationButtons"
import { useFormik } from "formik"
import { toasts } from "../../../../../services/toast.service"
import Page from "../../../../../layout/Page/Page"
import Collapse from "../../../../bootstrap/Collapse"
import Card, { CardActions, CardBody, CardFooter, CardFooterRight, CardHeader, CardLabel, CardTitle } from "../../../../bootstrap/Card"
import FormGroup from "../../../../bootstrap/forms/FormGroup"
import Select from "../../../../bootstrap/forms/Select"
import Button, { ButtonGroup } from "../../../../bootstrap/Button"
import Icon from "../../../../icon/Icon"
import Input from "../../../../bootstrap/forms/Input"
import useDarkMode from "../../../../../hooks/useDarkMode"
import Modal, { ModalBody, ModalFooter, ModalHeader, ModalTitle } from "../../../../bootstrap/Modal"
import AlertService from "../../../../../services/AlertService"
import { convertJsonToPdf, downloadExcel, downloadFile } from "../../../../../services/ExportService"
import Dropdown, { DropdownItem, DropdownMenu, DropdownToggle } from "../../../../bootstrap/Dropdown"
import Checks, { ChecksGroup } from "../../../../bootstrap/forms/Checks"
import { useReactToPrint } from "react-to-print"
import PageWrapper from "../../../../../layout/PageWrapper/PageWrapper"
import OpenCardComponent from "../../../../../common/components/OpenCardComponent"
import { convertDateToWords, getColumnsForDataTable, getGraduationType, getSectionDetails, updateColumnsForDataTable, updateFilter } from "../../../../../services/common.service"
import { getBatchByCourseId, getCourse, getDepartmentList, getSemester } from "../../../../../services/master.service"
import { getStudentDetailsBySemesterId, getStudentProfile } from "../../../../../services/student.service"
import { addStudentTransferCertificate, getNotIssuedTCList, getOverAllTransferCertificateDetails, getStudentTransferCertificate, updateStudentTransferCertificate } from "../../../../../services/certificate.service"
import AuthContext from "../../../../../contexts/authContext"
import { getLicenseKey } from "../../../../../services/application.settings"
import { showLoader } from "../../../../../services/loader.services"
import NoDataMsg from "../../../../../common/components/NoDataMsg"
import { getCollegeDetails } from "../../../../../services/college.service"
import CollegeLogo from '../../../../../assets/logos/logo.jpg';
import noImage from '../../../../../assets/img/noImage.png';
import SearchableSelect from "../../../../../common/components/SearchableSelect";
import '../../certificate/print/CertificateStyle.css';

function StudentTransferCertificate() {

    useEffect(() => {
        getGraduationList()
        getDepartment()
        getSectionList()
        getCollege();

    }, [])

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const { themeStatus, darkModeStatus } = useDarkMode();

    const [departmentId, setDepartmentId] = useState<any>('')
    const [courseId, setCourseId] = useState<any>('')
    const [batchMasterId, setBatchMasterId] = useState<any>('')
    const [semesterDetailsId, setSemesterDetailsId] = useState<any>('')
    const [sectionDetailsId, setSectionDetailsId] = useState<any>({ value: 1, label: 'A' })
    const [graduationTypeId, setGraduationTypeId] = useState<any>('')

    const [departmentData, setDepartmentData] = useState<any>([])
    const [courseData, setCourseData] = useState<any>([])
    const [graduationTypeData, setGraduationTypeData] = useState<any>([])
    const [batchMasterData, setBatchMasterData] = useState<any>([])
    const [semesterDetailsData, setSemesterDetailsData] = useState<any>([])
    const [sectionDetailsData, setSectionDetailsData] = useState<any>([])
    const [studentProfileData, setStudentProfileData] = useState([]);
    const [studentDetailsData, setStudentDetailsData] = useState([])
    const [notIssuedTcListData, setNotIssuedTcListData] = useState<any>([])
    const [isOpenListCard, setIsOpenListCard] = useState(true)
    const [dataSuccess, setDataSuccess] = useState(false)
    const [dataSuccess1, setDataSuccess1] = useState(false)
    const [dataSuccess2, setDataSuccess2] = useState(false)
    const [studentTransferCertificateData, setStudentTransferCertificateData] = useState([])
    const [studentDetailsId, setStudentDetailsId] = useState('')

    const [currentPage, setCurrentPage] = useState(1);
    const [perPage, setPerPage] = useState(10);
    const { items, requestSort, getClassNamesFor } = useSortableData(studentDetailsData);
    const onCurrentPageData = dataPagination(items, currentPage, perPage);

    const [dateOfBirthInWord, setDateOfBirthInWord] = useState<string>('');
    const [dateOfBirth, setDateOfBirth] = useState<string>('');
    const [columnVisibilityData, setColumnVisibilityData] = useState([]);
    const [allColumnsData, setAllColumnsData] = useState([]);
    const [columnVisibilityMenu, setColumnVisibilityMenu] = useState(false);
    const [columnDataById, setColumnDataById] = useState([])
    const componentRef = useRef(null);
    const [isChecked, setIsChecked] = useState(false)
    const [isLoader, setIsLoader] = useState(false)

    //Modal
    const [updateStudentTcModal, setUpdateStudentTcModal] = useState(false)
    const [addStudentTcModal, setAddStudentTcModal] = useState(false);
    const [pendingTCApprovalModal, setPendingTCApprovalModal] = useState(false);
    const [tcStatusMsg, setTcStatusMsg] = useState('');

    // For Alert
    const [alertStatus, setAlertStatus] = useState<any>({ message: "", type: "" });
    const [isOpen, setIsOpen] = useState(false);
    const [noDataMsg, setNoDataMsg] = useState('')

    const studentTransferCertificateOverAllAddForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { addStudentTransferCertificateOverAllSubmit() },
    })

    const studentTransferCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { addStudentTransferCertificateSubmit() },
    })

    const updateStudentTransferCertificateForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            studentDetailsId: '',
            admissionNo: "",
            studentName: "",
            fatherOrMotherName: "",
            religion: "",
            nationality: "",
            caste: "",
            community: "",
            gender: "",
            dateOfBirth: "",
            dateOfBirthInWord: "",
            admissionDate: "",
            degreeAndCourse: "",
            timeOfLeaving: "",
            leavingDate: "",
            leavingReason: '',
            qualifiedForHigherStudy: "",
            transferCertificateDate: "",
            conductAndCharacter: "",
            medium: "",
            serialNo: "",
        },
        validate: (values) => {
            const errors: {
                admissionNo?: string;
                studentName?: string;
                fatherOrMotherName?: string;
                gender?: string;
            } = {};
            if (!values.admissionNo) {
                errors.admissionNo = 'Required';
            }
            if (!values.studentName) {
                errors.studentName = 'Required';
            }
            if (!values.fatherOrMotherName) {
                errors.fatherOrMotherName = 'Required';
            }
            if (!values.gender) {
                errors.gender = 'Required';
            }
            return errors;

        },
        //validateOnChange: false,
        onSubmit: (values) => { updateStudentTransferCertificateSubmit() },
    })

    const columnVisibilityForm = useFormik({
        enableReinitialize: true,
        initialValues: {
            searchInput: '',
            available: false,
        },
        validate: (values) => { },

        //validateOnChange: false,
        onSubmit: () => { },
    });

    // Filter
    const filteredData = updateFilter(onCurrentPageData, columnVisibilityData, columnVisibilityForm);

    function onActivate(i: any) {
        if (i != undefined) {
            let selectedStudent = i
            setDateOfBirth(selectedStudent.dateOfBirth)
            setDateOfBirthInWord(convertDateToWords(selectedStudent.dateOfBirth))
            getStudentTransferCertificateList(selectedStudent.studentDetailsId)
            setStudentDetailsId(selectedStudent.studentDetailsId)
        }
    }

    const selectDepartment = (e: any) => {
        setGraduationTypeId('')
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setBatchMasterData([])
        setCourseData([])
        let departmentId = e
        setDepartmentId(departmentId)
    }

    const selectGraduationType = (e: any) => {
        setCourseId('')
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setBatchMasterData([])
        setCourseData([])
        let graduationTypeId = e
        setGraduationTypeId(graduationTypeId)
        if (graduationTypeId?.value != undefined) {
            getCourseList(departmentId?.value, graduationTypeId?.value)
        }
    }

    const selectCourse = (e: any) => {
        setBatchMasterId('')
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        setBatchMasterData([])
        let courseId = e
        setCourseId(courseId)
        if (graduationTypeId?.value != undefined) {
            getBatchList(courseId?.value, graduationTypeId?.value)
        }
    }

    const selectBatch = (e: any) => {
        setSemesterDetailsId('')
        setSemesterDetailsData([])
        let batchMasterId = e
        setBatchMasterId(batchMasterId)
        if (batchMasterId?.value != undefined) {
            getSemesterList(batchMasterId?.value)
        }
    }

    const selectSemester = (e: any) => {
        let semesterDetailsId = e
        setSemesterDetailsId(semesterDetailsId)
    }

    const selectSection = (e: any) => {
        let sectionDetailsId = e
        setSectionDetailsId(sectionDetailsId)
    }

    const studentList = () => {
        columnVisibilityForm.resetForm()
        setDataSuccess(false)
        setIsLoader(true);
        setDataSuccess1(false)
        setDataSuccess2(false)
        getStudentListBySectionId(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    const overAllAdd = () => {
        setDataSuccess(false)
        setIsLoader(true);
        setDataSuccess1(false)
        setDataSuccess2(false)
        getOverAllAdd(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    const convertDateOfBirth = (e: any) => {
        let dateOfBirth = e.target.value
        setDateOfBirth(dateOfBirth)
        setDateOfBirthInWord(convertDateToWords(dateOfBirth))
    };

    function getStudentListBySectionId(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        setIsLoader(true);
        getStudentDetailsBySemesterId(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentDetailsBySemesterId;
                    if (data != undefined) {
                        setIsLoader(false);
                        getColumnsForTable('getStudentListBySectionId', 'get')
                        setStudentDetailsData(data);
                        setDataSuccess(true);
                        setIsOpenListCard(false);
                        for (let i = 0; i < data.length; i++) {
                            data[i].sno = i + 1;
                        }
                    } else {
                        setIsLoader(false);
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    getColumnsForTable('getStudentListBySectionId', 'get')
                    setStudentDetailsData([]);
                    setIsLoader(false);
                    setIsOpenListCard(false);
                    //toasts(response.data.message, "Error")
                    setDataSuccess(true);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getOverAllAdd(courseId: number, semesterDetailsId: number, sectionDetailsId: number) {
        setIsLoader(true);
        getNotIssuedTCList(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.notIssuedTCList[0];
                    if (data != undefined) {
                        setIsLoader(false);
                        setNotIssuedTcListData(data);
                        setDataSuccess2(true);
                        setIsOpenListCard(false);
                        setDateOfBirth(data.dateOfBirth);
                        setDateOfBirthInWord(data.dateOfBirthInWords);
                        studentTransferCertificateOverAllAddForm.setValues({
                            studentDetailsId: data.studentDetailsId,
                            admissionNo: data.admissionNoForTC ? data.admissionNoForTC : "",
                            studentName: data.studentName ? data.studentName : "",
                            fatherOrMotherName: data.fatherName ? data.fatherName : "",
                            religion: data.religion ? data.religion : "",
                            nationality: data.nationality ? data.nationality : "",
                            caste: data.casteName ? data.casteName : "",
                            community: data.category ? data.category : "",
                            gender: data.gender ? data.gender : "",
                            dateOfBirth: data.dateOfBirth ? data.dateOfBirth : "",
                            dateOfBirthInWord: data.dateOfBirthInWords ? data.dateOfBirthInWords : "",
                            admissionDate: data.admissionDateStamp ? data.admissionDateStamp : "",
                            degreeAndCourse: data.admittedYear == null ? data.courseName : data.admittedYear + " " + data.courseName,
                            timeOfLeaving: data.currentYear + " - " + data.courseName,
                            leavingDate: data.leavingDate ? data.leavingDate : "",
                            leavingReason: data.leavingReason ? data.leavingReason : "",
                            qualifiedForHigherStudy: data.qualifiedForHigherStudy ? data.qualifiedForHigherStudy : "",
                            transferCertificateDate: data.transferCertificateDate ? data.transferCertificateDate : "",
                            conductAndCharacter: data.conductAndCharacter ? data.conductAndCharacter : "",
                            medium: data.medium ? data.medium : "",
                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setDataSuccess2(false);
                    setIsOpenListCard(true);
                    // toasts(response.data.message, "Error")
                    setNotIssuedTcListData([]);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    setIsLoader(false);
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                toasts(error, "Error")
            }
        )
    }

    function getDepartment() {
        getDepartmentList(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.department;
                    if (data != undefined) {
                        setDepartmentData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setDepartmentData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getGraduationList() {
        getGraduationType(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.graduationType;
                    if (data != undefined) {
                        setGraduationTypeData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    setGraduationTypeData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getCourseList(departmentId: any, graduationTypeId: any) {
        getCourse(departmentId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.course;
                    if (data != undefined) {
                        setCourseData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setCourseData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getBatchList(courseId: any, graduationTypeId: any) {
        getBatchByCourseId(courseId, graduationTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.batchByCourseId;
                    if (data != undefined) {
                        setBatchMasterData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setBatchMasterData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSemesterList(batchMasterId: number) {
        getSemester(batchMasterId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.semester;
                    if (data != undefined) {
                        setSemesterDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSemesterDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getSectionList() {
        getSectionDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.sectionDetails;
                    if (data != undefined) {
                        setSectionDetailsData(data);
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setSectionDetailsData([]);
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentTransferCertificateList(studentDetailsId: any) {
        getStudentTransferCertificate(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.studentTransferCertificate[0];
                    let statusId = response.data.data.studentTransferCertificate[0].statusId;
                    let tcStatusMsg = response.data.data.studentTransferCertificate[0].statusMessage;
                    if (data != undefined) {
                        setStudentTransferCertificateData(data);
                        if (statusId == 1) {
                            setPendingTCApprovalModal(true)
                            setTcStatusMsg(tcStatusMsg);
                        } else {
                            setUpdateStudentTcModal(true);
                            updateStudentTransferCertificateForm.setValues({
                                studentDetailsId: data.studentDetailsId,
                                serialNo: data.serialNo,
                                admissionNo: data.admissionNo,
                                studentName: data.studentName,
                                fatherOrMotherName: data.fatherOrMotherName ? data.fatherOrMotherName : '',
                                religion: data.religion ? data.religion : '',
                                nationality: data.nationality ? data.nationality : '',
                                caste: data.caste ? data.caste : "",
                                community: data.community ? data.community : '',
                                gender: data.gender ? data.gender : '',
                                dateOfBirth: data.dateOfBirth ? data.dateOfBirth : '',
                                dateOfBirthInWord: dateOfBirthInWord,
                                admissionDate: data.admissionDate ? data.admissionDate : '',
                                degreeAndCourse: data.degreeAndCourse ? data.degreeAndCourse : '',
                                timeOfLeaving: data.timeOfLeaving ? data.timeOfLeaving : '',
                                leavingDate: data.leavingDate ? data.leavingDate : '',
                                leavingReason: data.leavingReason ? data.leavingReason : '',
                                qualifiedForHigherStudy: data.qualifiedForHigherStudy ? data.qualifiedForHigherStudy : '',
                                transferCertificateDate: data.transferCertificateDate ? data.transferCertificateDate : '',
                                conductAndCharacter: data.conductAndCharacter ? data.conductAndCharacter : '',
                                medium: data.medium ? data.medium : '',
                            });
                        }

                    } else {
                        //toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    // toasts(response.data.message, "Error")
                    getStudentProfileList(studentDetailsId)

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function getStudentProfileList(studentDetailsId: any) {
        getStudentProfile(studentDetailsId,
            (response) => {
                if (response.data.success) {
                    let profileData = response.data.data.studentProfile[0];
                    let data = response.data.data.studentProfile[0].tcDetails;
                    if (data != undefined) {
                        setStudentProfileData(profileData);
                        setAddStudentTcModal(true);
                        studentTransferCertificateForm.setValues({
                            studentDetailsId: data.studentDetailsId,
                            admissionNo: data.admissionNoForTC ? data.admissionNoForTC : "",
                            studentName: data.studentName ? data.studentName : "",
                            fatherOrMotherName: data.fatherName ? data.fatherName : "",
                            religion: data.religion ? data.religion : "",
                            nationality: data.nationality ? data.nationality : "",
                            caste: data.casteName ? data.casteName : "",
                            community: data.category ? data.category : "",
                            gender: data.gender ? data.gender : "",
                            dateOfBirth: data.dateOfBirth ? data.dateOfBirth : "",
                            dateOfBirthInWord: data.dateOfBirthInWords ? data.dateOfBirthInWords : "",
                            admissionDate: data.admissionDateStamp ? data.admissionDateStamp : "",
                            degreeAndCourse: data.admittedYear == null ? data.courseName : data.admittedYear + " " + data.courseName,
                            timeOfLeaving: data.currentYear + " - " + data.courseName,
                            leavingDate: data.leavingDate ? data.leavingDate : "",
                            leavingReason: data.leavingReason ? data.leavingReason : "",
                            qualifiedForHigherStudy: data.qualifiedForHigherStudy ? data.qualifiedForHigherStudy : "",
                            transferCertificateDate: data.transferCertificateDate ? data.transferCertificateDate : "",
                            conductAndCharacter: data.conductAndCharacter ? data.conductAndCharacter : "",
                            medium: data.medium ? data.medium : "",
                        });
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")

                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )
    }

    function setOverAllStudentTransferCertificateDetails() {
        return ({
            studentDetailsId: studentTransferCertificateOverAllAddForm.values.studentDetailsId != "" ? studentTransferCertificateOverAllAddForm.values.studentDetailsId : null,
            admissionNo: studentTransferCertificateOverAllAddForm.values.admissionNo != "" ? studentTransferCertificateOverAllAddForm.values.admissionNo : null,
            studentName: studentTransferCertificateOverAllAddForm.values.studentName != "" ? studentTransferCertificateOverAllAddForm.values.studentName : null,
            fatherOrMotherName: studentTransferCertificateOverAllAddForm.values.fatherOrMotherName != "" ? studentTransferCertificateOverAllAddForm.values.fatherOrMotherName : null,
            religion: studentTransferCertificateOverAllAddForm.values.religion != "" ? studentTransferCertificateOverAllAddForm.values.religion : null,
            nationality: studentTransferCertificateOverAllAddForm.values.nationality != "" ? studentTransferCertificateOverAllAddForm.values.nationality : null,
            caste: studentTransferCertificateOverAllAddForm.values.caste != "" ? studentTransferCertificateOverAllAddForm.values.caste : null,
            community: studentTransferCertificateOverAllAddForm.values.community != "" ? studentTransferCertificateOverAllAddForm.values.community : null,
            gender: studentTransferCertificateOverAllAddForm.values.gender != "" ? studentTransferCertificateOverAllAddForm.values.gender : null,
            dateOfBirth: dateOfBirth != "" ? dateOfBirth : null,
            dateOfBirthInWord: dateOfBirthInWord != "" ? dateOfBirthInWord : null,
            admissionDate: studentTransferCertificateOverAllAddForm.values.admissionDate != "" ? studentTransferCertificateOverAllAddForm.values.admissionDate : null,
            degreeAndCourse: studentTransferCertificateOverAllAddForm.values.degreeAndCourse != "" ? studentTransferCertificateOverAllAddForm.values.degreeAndCourse : null,
            timeOfLeaving: studentTransferCertificateOverAllAddForm.values.timeOfLeaving != "" ? studentTransferCertificateOverAllAddForm.values.timeOfLeaving : null,
            leavingDate: studentTransferCertificateOverAllAddForm.values.leavingDate != "" ? studentTransferCertificateOverAllAddForm.values.leavingDate : null,
            leavingReason: studentTransferCertificateOverAllAddForm.values.leavingReason != "" ? studentTransferCertificateOverAllAddForm.values.leavingReason : null,
            qualifiedForHigherStudy: studentTransferCertificateOverAllAddForm.values.qualifiedForHigherStudy != "" ? studentTransferCertificateOverAllAddForm.values.qualifiedForHigherStudy : null,
            transferCertificateDate: studentTransferCertificateOverAllAddForm.values.transferCertificateDate != "" ? studentTransferCertificateOverAllAddForm.values.transferCertificateDate : null,
            conductAndCharacter: studentTransferCertificateOverAllAddForm.values.conductAndCharacter != "" ? studentTransferCertificateOverAllAddForm.values.conductAndCharacter : null,
            medium: studentTransferCertificateOverAllAddForm.values.medium != "" ? studentTransferCertificateOverAllAddForm.values.medium : null,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setStudentTransferCertificateDetails() {
        return ({
            studentDetailsId: studentTransferCertificateForm.values.studentDetailsId != "" ? studentTransferCertificateForm.values.studentDetailsId : null,
            admissionNo: studentTransferCertificateForm.values.admissionNo != "" ? studentTransferCertificateForm.values.admissionNo : null,
            studentName: studentTransferCertificateForm.values.studentName != "" ? studentTransferCertificateForm.values.studentName : null,
            fatherOrMotherName: studentTransferCertificateForm.values.fatherOrMotherName != "" ? studentTransferCertificateForm.values.fatherOrMotherName : null,
            religion: studentTransferCertificateForm.values.religion != "" ? studentTransferCertificateForm.values.religion : null,
            nationality: studentTransferCertificateForm.values.nationality != "" ? studentTransferCertificateForm.values.nationality : null,
            caste: studentTransferCertificateForm.values.caste != "" ? studentTransferCertificateForm.values.caste : null,
            community: studentTransferCertificateForm.values.community != "" ? studentTransferCertificateForm.values.community : null,
            gender: studentTransferCertificateForm.values.gender != "" ? studentTransferCertificateForm.values.gender : null,
            dateOfBirth: dateOfBirth != "" ? dateOfBirth : null,
            dateOfBirthInWord: dateOfBirthInWord != "" ? dateOfBirthInWord : null,
            admissionDate: studentTransferCertificateForm.values.admissionDate != "" ? studentTransferCertificateForm.values.admissionDate : null,
            degreeAndCourse: studentTransferCertificateForm.values.degreeAndCourse != "" ? studentTransferCertificateForm.values.degreeAndCourse : null,
            timeOfLeaving: studentTransferCertificateForm.values.timeOfLeaving != "" ? studentTransferCertificateForm.values.timeOfLeaving : null,
            leavingDate: studentTransferCertificateForm.values.leavingDate != "" ? studentTransferCertificateForm.values.leavingDate : null,
            leavingReason: studentTransferCertificateForm.values.leavingReason != "" ? studentTransferCertificateForm.values.leavingReason : null,
            qualifiedForHigherStudy: studentTransferCertificateForm.values.qualifiedForHigherStudy != "" ? studentTransferCertificateForm.values.qualifiedForHigherStudy : null,
            transferCertificateDate: studentTransferCertificateForm.values.transferCertificateDate != "" ? studentTransferCertificateForm.values.transferCertificateDate : null,
            conductAndCharacter: studentTransferCertificateForm.values.conductAndCharacter != "" ? studentTransferCertificateForm.values.conductAndCharacter : null,
            medium: studentTransferCertificateForm.values.medium != "" ? studentTransferCertificateForm.values.medium : null,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function setEditStudentTransferCertificateDetails() {
        return ({
            studentDetailsId: updateStudentTransferCertificateForm.values.studentDetailsId != "" ? updateStudentTransferCertificateForm.values.studentDetailsId : null,
            serialNo: updateStudentTransferCertificateForm.values.serialNo != "" ? updateStudentTransferCertificateForm.values.serialNo : null,
            admissionNo: updateStudentTransferCertificateForm.values.admissionNo != "" ? updateStudentTransferCertificateForm.values.admissionNo : null,
            studentName: updateStudentTransferCertificateForm.values.studentName != "" ? updateStudentTransferCertificateForm.values.studentName : null,
            fatherOrMotherName: updateStudentTransferCertificateForm.values.fatherOrMotherName != "" ? updateStudentTransferCertificateForm.values.fatherOrMotherName : null,
            religion: updateStudentTransferCertificateForm.values.religion != "" ? updateStudentTransferCertificateForm.values.religion : null,
            nationality: updateStudentTransferCertificateForm.values.nationality != "" ? updateStudentTransferCertificateForm.values.nationality : null,
            caste: updateStudentTransferCertificateForm.values.caste != "" ? updateStudentTransferCertificateForm.values.caste : null,
            community: updateStudentTransferCertificateForm.values.community != "" ? updateStudentTransferCertificateForm.values.community : null,
            gender: updateStudentTransferCertificateForm.values.gender != "" ? updateStudentTransferCertificateForm.values.gender : null,
            dateOfBirth: dateOfBirth != "" ? dateOfBirth : null,
            dateOfBirthInWord: dateOfBirthInWord != "" ? dateOfBirthInWord : null,
            admissionDate: updateStudentTransferCertificateForm.values.admissionDate != "" ? updateStudentTransferCertificateForm.values.admissionDate : null,
            degreeAndCourse: updateStudentTransferCertificateForm.values.degreeAndCourse != "" ? updateStudentTransferCertificateForm.values.degreeAndCourse : null,
            timeOfLeaving: updateStudentTransferCertificateForm.values.timeOfLeaving != "" ? updateStudentTransferCertificateForm.values.timeOfLeaving : null,
            leavingDate: updateStudentTransferCertificateForm.values.leavingDate != "" ? updateStudentTransferCertificateForm.values.leavingDate : null,
            leavingReason: updateStudentTransferCertificateForm.values.leavingReason != "" ? updateStudentTransferCertificateForm.values.leavingReason : null,
            qualifiedForHigherStudy: updateStudentTransferCertificateForm.values.qualifiedForHigherStudy != "" ? updateStudentTransferCertificateForm.values.qualifiedForHigherStudy : null,
            transferCertificateDate: updateStudentTransferCertificateForm.values.transferCertificateDate != "" ? updateStudentTransferCertificateForm.values.transferCertificateDate : null,
            conductAndCharacter: updateStudentTransferCertificateForm.values.conductAndCharacter != "" ? updateStudentTransferCertificateForm.values.conductAndCharacter : null,
            medium: updateStudentTransferCertificateForm.values.medium != "" ? updateStudentTransferCertificateForm.values.medium : null,
            licenseKey: getLicenseKey,
            userAccountId: userAccountId
        })
    }

    function addStudentTransferCertificateOverAllSubmit() {
        showLoader(true);
        if (studentTransferCertificateOverAllAddForm.isValid) {
            let addStudentTransferCertificatePost = setOverAllStudentTransferCertificateDetails()
            addStudentTransferCertificate(addStudentTransferCertificatePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        overAllAdd();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentTransferCertificateOverAllAddForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function addStudentTransferCertificateSubmit() {
        showLoader(true);
        if (studentTransferCertificateForm.isValid) {
            let addStudentTransferCertificatePost = setStudentTransferCertificateDetails()
            addStudentTransferCertificate(addStudentTransferCertificatePost,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        studentList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (studentTransferCertificateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function updateStudentTransferCertificateSubmit() {
        showLoader(true);
        if (updateStudentTransferCertificateForm.isValid) {
            let transferCertificatePostData = setEditStudentTransferCertificateDetails()
            updateStudentTransferCertificate(transferCertificatePostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "success" });
                        setIsOpen(true);
                        studentList();
                        closeAndReset();
                    }
                    else if (data.success == false) {
                        showLoader(false);
                        setAlertStatus({ message: data.message, type: "error" });
                        setIsOpen(true);
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        showLoader(false);
                        setAlertStatus({ message: errorDescription, type: "error" });
                        setIsOpen(true);
                    }
                }, (error) => {
                    showLoader(false);
                    setAlertStatus({ message: error, type: "error" });
                    setIsOpen(true);
                }
            )
        } else if (updateStudentTransferCertificateForm.isValid == false) {
            showLoader(false);
            setAlertStatus({ message: 'Please fill all the details!', type: "error" });
            setIsOpen(true);
        }
    }

    function getColumnsForTable(apiName: any, type: any) {
        getColumnsForDataTable(userTypeId, apiName,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.columnsForDataTable;
                    if (data != undefined) {
                        let allColumnsData = data;
                        setAllColumnsData(allColumnsData)
                        let columnVisibilityData = allColumnsData.filter((item: any) => item.isDisplay == 1);
                        setColumnVisibilityData(columnVisibilityData)

                        if (type == 'get') {
                            const allData = allColumnsData.map((item: any) => item.isDisplay);
                            const allColumns = allData.filter((item: any) => item == false);
                            setIsChecked(allColumns == '' ? true : false);
                        }
                        else if (type == 'post') {
                            const all = [];
                            let nonDefaultValue = allColumnsData.filter((item: any) => item.isDefault == false)

                            for (let i = 0; i < nonDefaultValue.length; i++) {

                                const obj = {
                                    columnVisibilityId: nonDefaultValue[i].columnVisibilityId,
                                    isDisplay: nonDefaultValue[0].isDisplay == 0 ? 1 : 0
                                }
                                all.push(obj);
                            }
                            updateColumnsSubmit('', '', all)
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    toasts(response.data.message, "Error")
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                toasts(error, "Error")
            }
        )

    }

    function updateColumnsSubmit(columnVisibilityId: any, isDisplay: any, type: any) {

        if (columnVisibilityForm.isValid) {

            setColumnDataById(isDisplay)
            const columnVisibilityPostData = {
                licenseKey: getLicenseKey,
                userAccountId: userAccountId,
                columns: type ? type : [
                    {
                        columnVisibilityId: columnVisibilityId,
                        isDisplay: isDisplay ? 0 : 1
                    }
                ]
            }

            updateColumnsForDataTable(columnVisibilityPostData,
                (response) => {
                    const data = response.data;
                    if (data.success == true) {
                        getColumnsForTable('getStudentListBySectionId', 'get');
                    }
                    else if (data.success == false) {
                        // toasts(data.success, "Error")
                    }
                    else {
                        let errorCode = response.data.error[0].error_code;
                        let errorDescription = response.data.error[0].error_description;
                        toasts(errorDescription, "Error")
                    }
                }
                , (error) => {
                    toasts(error, "Error")
                }
            )
        } else if (columnVisibilityForm.isValid == false) {
            toasts("Please fill all the details!", "Error")
        }
    }

    function closeAndReset() {
        setAddStudentTcModal(false)
        setUpdateStudentTcModal(false)
        studentTransferCertificateForm.resetForm()
        updateStudentTransferCertificateForm.resetForm()
    }

    const overAllPrint = () => {
        setDataSuccess(false)
        setIsLoader(true);
        setDataSuccess1(false)
        setDataSuccess2(false)
        getOverAllTransferCertificateDetailsForPrint(courseId?.value, semesterDetailsId?.value, sectionDetailsId?.value)
    }

    const [studentTCData, setStudentTCData] = useState<any>([])
    const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);

    const [ugOrPgStudent, setUgOrPgStudent] = useState('')

    function getOverAllTransferCertificateDetailsForPrint(courseId: number, semesterDetailsId: number, sectionDetailsId: number,) {
        setIsLoader(true);
        getOverAllTransferCertificateDetails(courseId, semesterDetailsId, sectionDetailsId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.overAllTransferCertificateDetails;
                    if (data != undefined) {
                        setIsLoader(false);
                        setIsOpenListCard(false);
                        setStudentTCData(data);
                        setDataSuccess2(true)
                        let graduationTypeId = data[0].graduationTypeId
                        if (graduationTypeId == 1) {
                            setUgOrPgStudent('isUgStudent');
                        }
                        else {
                            setUgOrPgStudent('isNonUgStudent');
                        }
                    } else {
                        toasts("Undefined Data", "Error")
                    }
                }
                else if (response.data.success === false) {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    setStudentTCData([]);
                    setAlertStatus({ message: response.data.message, type: "error" });
                    setIsOpen(true);

                } else {
                    setIsLoader(false);
                    setIsOpenListCard(true);
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, "Error")
                }
            }, error => {
                setIsLoader(false);
                setIsOpenListCard(true);
                toasts(error, "Error")
            }
        )
    }

    function getCollege() {
        getCollegeDetails(
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.collegeDetails;
                    if (data != undefined) {
                        setCollegeDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    toasts(response.data.message, 'Error');
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }

    const handlePrint = useReactToPrint({
        content: () => componentRef.current,
    });

    return (
        <PageWrapper title="Student Transfer Certificate">
            <Page container='fluid'>
                <Collapse isOpen={isOpenListCard}>
                    <Card stretch data-tour='list'>
                        <CardHeader borderSize={1}>
                            <CardLabel icon='List' iconColor='info'>
                                <CardTitle tag='div' className='h5'>
                                    Student Transfer Certificate
                                </CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className='row'>
                                <div className='col-3'>
                                    <FormGroup id='departmentId' label='Department' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Department' onChange={selectDepartment}
                                            value={departmentId} list={departmentData.map((data: any) => ({ value: data.departmentId, label: data.departmentName }))} />
                                    </FormGroup>
                                </div>

                                {departmentId?.value != undefined ? <div className='col-3'>
                                    <FormGroup id='graduationTypeId' label='Graduation Type' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Graduation Type' onChange={selectGraduationType} value={graduationTypeId} list={graduationTypeData.map((data: any) => ({ value: data.graduationTypeId, label: data.graduationType }))} />
                                    </FormGroup>
                                </div> : null}

                                {graduationTypeId?.value != undefined ? <div className='col-3'>
                                    <FormGroup id='courseId' label='Course' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Course' onChange={selectCourse} value={courseId} list={courseData.map((data: any) => ({ value: data.courseId, label: data.courseName }
                                        ))} />
                                    </FormGroup>
                                </div> : null}

                                {courseId?.value != undefined ? <div className='col-3'>
                                    <FormGroup id='batchMasterId' label='Batch' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Batch' onChange={selectBatch} value={batchMasterId} list={batchMasterData.map((data: any) => ({ value: data.batchMasterId, label: data.batchName }))} />
                                    </FormGroup>
                                </div> : null}

                                {batchMasterId?.value != undefined ? <div className='col-3 mt-3'>
                                    <FormGroup id='semesterDetailsId' label='Semester' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Semester' onChange={selectSemester} value={semesterDetailsId}
                                            list={semesterDetailsData.map((data: any) => ({ value: data.semesterDetailsId, label: data.semesterNumber }))} />
                                    </FormGroup>
                                </div> : null}

                                {semesterDetailsId?.value != undefined ? <div className='col-3 mt-3'>
                                    <FormGroup id='sectionDetailsId' label='Section' isFloating>
                                        <SearchableSelect isFloating ariaLabel='Section' onChange={selectSection} value={sectionDetailsId}
                                            list={sectionDetailsData.map((data: any) => ({ value: data.sectionDetailsId, label: data.sectionName }))} />
                                    </FormGroup>
                                </div> : null}

                                {semesterDetailsId?.value != undefined && sectionDetailsId?.value != undefined ? <div className="col-6 mt-4">
                                    <Button icon='ArrowDownward' color='info' onClick={studentList}>View</Button>
                                    &nbsp;&nbsp;
                                    <Button icon='Add' color='primary' onClick={overAllAdd}>Over All Add</Button>
                                    &nbsp;&nbsp;
                                    <Button icon='Save' color='primary' onClick={overAllPrint}>Over All Print</Button>
                                </div> : null}
                            </div>
                        </CardBody>
                    </Card>
                </Collapse>
                <OpenCardComponent isOpenListCard={isOpenListCard} setIsOpenListCard={setIsOpenListCard} isLoader={isLoader} />

                {dataSuccess ?
                    <>
                        <Card stretch data-tour='list' ref={componentRef}>
                            <CardHeader borderSize={1}>
                                <CardLabel icon='List' iconColor='info' className="col-lg-6">
                                    <CardTitle tag='div' className='h5'>Transfer Certificate</CardTitle>
                                </CardLabel>&nbsp;&nbsp;

                                <ButtonGroup className="col-lg-3 justify-content-end d-print-none" color='primary'>
                                    <Icon className="mt-1" icon='Search' size='2x' color='primary' />
                                    <Input id='searchInput' type='search' placeholder='Search...' onChange={columnVisibilityForm.handleChange} value={columnVisibilityForm.values.searchInput} />
                                </ButtonGroup>

                                <CardActions className="d-print-none">
                                    <Dropdown isOpen={columnVisibilityMenu} setIsOpen={setColumnVisibilityMenu} isButtonGroup>
                                        <DropdownToggle>
                                            <Button icon='FilterAlt' color='primary' isLight>Filter</Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd size='lg' isCloseAfterLeave={false}>
                                            <DropdownItem>
                                                <div className='container py-2'>
                                                    <form className='row g-3'>
                                                        <div className='col-12'>
                                                            <FormGroup>
                                                                <h6>Select All</h6>
                                                                <Checks id='available' type='switch' label='Select All Columns' onChange={() => getColumnsForTable('getStudentListBySectionId', 'post')} checked={isChecked} ariaLabel='Available status' />
                                                            </FormGroup>
                                                        </div>
                                                        <div className='col-12' style={{ maxHeight: '200px', overflowY: 'scroll' }}>
                                                            <FormGroup>
                                                                <h6>Columns</h6>
                                                                <ChecksGroup>
                                                                    {allColumnsData.map((i: any) => (
                                                                        <Checks key={i.columnVisibilityId} id='{i.columnVisibilityId}' label={i.columnName} onChange={() => updateColumnsSubmit(i.columnVisibilityId, i.isDisplay, '')} checked={i.isDisplay} disabled={i.isDefault == true} />
                                                                    ))}
                                                                </ChecksGroup>
                                                            </FormGroup>
                                                        </div>
                                                    </form>
                                                </div>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>
                                    <Dropdown isButtonGroup>
                                        <DropdownToggle>
                                            <Button color='primary' isLight icon='CloudDownload'> Export </Button>
                                        </DropdownToggle>
                                        <DropdownMenu isAlignmentEnd>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadExcel("Student Transfer Certificate List", studentDetailsData, columnVisibilityData)}> Excel </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='PictureAsPdf' onClick={() => convertJsonToPdf(studentDetailsData, columnVisibilityData, "Student Transfer Certificate List")} > PDF
                                                </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='CloudDownload' onClick={() => downloadFile("Student Transfer Certificate List", studentDetailsData, columnVisibilityData)}> CSV </Button>
                                            </DropdownItem>
                                            <DropdownItem>
                                                <Button color='primary' isLight icon='Print' onClick={handlePrint} > Print </Button>
                                            </DropdownItem>
                                        </DropdownMenu>
                                    </Dropdown>

                                </CardActions>
                            </CardHeader>
                            <CardBody className='table-responsive' isScrollable>
                                <table className='table table-modern table-hover text-nowrap'>
                                    <thead>
                                        <tr>
                                            <th scope='col' onClick={() => requestSort('sno')}
                                                className='cursor-pointer text-decoration-underline'>S.No{' '}
                                                <Icon size='lg' className={getClassNamesFor('sno')} icon='FilterList' />
                                            </th>
                                            {columnVisibilityData.map((column: any) => (
                                                <th key={column.keyName} scope='col' onClick={() => requestSort(column.keyName)}
                                                    className='cursor-pointer text-decoration-underline'>
                                                    {column.columnName}
                                                    <Icon size='lg' className={getClassNamesFor(column.keyName)} icon='FilterList' />
                                                </th>
                                            ))}
                                            <th scope='col' className='d-print-none'>
                                                Action
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody>{filteredData != "" ? <>{filteredData.map((rowData: any) => (<tr key={rowData.studentDetailsId}><td>{rowData.sno}</td>{columnVisibilityData.map((column: any) => ((column.isDisplay && rowData[column.keyName]) ? <td key={`${rowData.studentDetailsId}-${column.keyName}`}>{rowData[column.keyName]}</td> : <td key={`empty-${column.keyName}`} />))}<td className='d-print-none'><Button isLink icon='Circle' className='text-nowrap' color={rowData.tcStatusId == 0 ? 'info' : rowData.tcStatusId == 1 ? 'danger' : 'success'} onClick={() => { onActivate(rowData) }}>{rowData.tcStatus}</Button></td></tr>))} </> : <NoDataMsg columnsCount={columnVisibilityData.length + 3} msg={noDataMsg} />}</tbody>
                                </table>
                            </CardBody>
                            <PaginationButtons className="d-print-none" data={items} label='items' setCurrentPage={setCurrentPage} currentPage={currentPage} perPage={perPage} setPerPage={setPerPage} />
                        </Card></>
                    : null}

                {dataSuccess1 ?
                    <Card stretch data-tour='list' tag='form' noValidate onSubmit={studentTransferCertificateOverAllAddForm.handleSubmit}>
                        <CardHeader borderSize={1}>
                            <CardLabel icon="ReceiptLong" iconColor='info'>
                                <CardTitle tag='div' className='h5'>Transfer Certificate</CardTitle>
                            </CardLabel>
                        </CardHeader>
                        <CardBody>
                            <div className="d-grid gap-4">

                                <div className="row">
                                    <div className="d-flex col-lg-12 justify-content-end">
                                        <div className="align-self-center" style={{ marginRight: '20px' }}>
                                            <p className="h6">Admission No :</p>
                                        </div>
                                        <div>
                                            <FormGroup id="admissionNo" label="">
                                                <Input type="text" placeholder="Admission Number" style={{ width: '370px' }}
                                                    onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                    value={studentTransferCertificateOverAllAddForm.values.admissionNo}
                                                    onBlur={studentTransferCertificateOverAllAddForm.handleBlur}
                                                    isValid={studentTransferCertificateOverAllAddForm.isValid}
                                                    isTouched={studentTransferCertificateOverAllAddForm.touched.admissionNo}
                                                    invalidFeedback={studentTransferCertificateOverAllAddForm.errors.admissionNo} />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>1.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">

                                            Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)

                                        </p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="studentName"  >
                                            <Input type="text" placeholder="Name Of Student" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.studentName} onBlur={studentTransferCertificateOverAllAddForm.handleBlur} isValid={studentTransferCertificateOverAllAddForm.isValid} isTouched={studentTransferCertificateOverAllAddForm.touched.studentName} invalidFeedback={studentTransferCertificateOverAllAddForm.errors.studentName} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>2.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">
                                            Name of the Father or Mother of the Pupil
                                        </p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="fatherOrMotherName"  >
                                            <Input type="text" placeholder="Name Of Father Or Mother" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.fatherOrMotherName} onBlur={studentTransferCertificateOverAllAddForm.handleBlur} isValid={studentTransferCertificateOverAllAddForm.isValid} isTouched={studentTransferCertificateOverAllAddForm.touched.fatherOrMotherName} invalidFeedback={studentTransferCertificateOverAllAddForm.errors.fatherOrMotherName} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>3.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">Nationality</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="nationality"  >
                                            <Input type="text" placeholder="Nationality" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.nationality} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>4.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">Religion</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="religion"  >
                                            <Input type="text" placeholder="Religion" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.religion} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>5.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">Caste</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="caste"  >
                                            <Input type="text" placeholder="Caste" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.caste} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>6.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">Community</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="community"  >
                                            <Input type="text" placeholder="Community" onChange={studentTransferCertificateOverAllAddForm.handleChange} value={studentTransferCertificateOverAllAddForm.values.community} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>7.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start">
                                        <p className="h6">Gender</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center">
                                        <p className="h6">:</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="gender"  >
                                            <Input type="text" placeholder="Male Or Female"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.gender}
                                                onBlur={studentTransferCertificateOverAllAddForm.handleBlur}
                                                isValid={studentTransferCertificateOverAllAddForm.isValid}
                                                isTouched={studentTransferCertificateOverAllAddForm.touched.gender}
                                                invalidFeedback={studentTransferCertificateOverAllAddForm.errors.gender} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>8.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Date of Birth as entered in the Admission</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirth"  >
                                            <Input type="date" placeholder="Date Of Birth"
                                                onChange={convertDateOfBirth}
                                                value={dateOfBirth} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>9.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Date of Birth in Word</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirthInWord">
                                            <Input type="text" placeholder="Date Of Birth"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={dateOfBirthInWord} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>10.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Date of Admission and class in which
                                        admitted</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="admissionDate"  >
                                            <Input type="date" placeholder="Date of Admission and class in which admitted"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.admissionDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>11.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Degree and Programme to which the student
                                        was admitted</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="degreeAndCourse"  >
                                            <Input type="text" placeholder="Degree and Programme"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.degreeAndCourse} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>12.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Class in which the pupil was studying at
                                        the time of leaving</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="timeOfLeaving"   >
                                            <Input type="text" placeholder="Class"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.timeOfLeaving} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>13.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Date on which the student actually left the
                                        College</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingDate" >
                                            <Input type="date" placeholder="Date "
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.leavingDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>14.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Reason for which the student left the College</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingReason" >
                                            <Input type="text" placeholder="Reason "
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.leavingReason} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>15.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Whether qualified for promotion to higher
                                        studies</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="qualifiedForHigherStudy">
                                            <Input type="text" placeholder="Qualified for promotion"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.qualifiedForHigherStudy} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>16.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Date of the Transfer Certificate</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="transferCertificateDate" >
                                            <Input type="date" placeholder="Date Of Transfer Certificate"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.transferCertificateDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>17.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Conduct and Character</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="conductAndCharacter">
                                            <Input type="text" placeholder="Conduct And Character"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.conductAndCharacter} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-end h6">
                                        <p>18.</p>
                                    </div>
                                    <div className="col-lg-5 justify-content-start"><p className="h6">Medium(for M.Phil Only)</p>
                                    </div>
                                    <div className="col-lg-1 justify-content-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="medium" >
                                            <Input type="text" placeholder="Medium"
                                                onChange={studentTransferCertificateOverAllAddForm.handleChange}
                                                value={studentTransferCertificateOverAllAddForm.values.medium} />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </CardBody>
                        <CardFooter>
                            <CardFooterRight>
                                <Button color='info' icon='Save' type="submit" isDisable={!studentTransferCertificateOverAllAddForm.isValid && !!studentTransferCertificateOverAllAddForm.submitCount}>Save </Button>
                            </CardFooterRight>
                        </CardFooter>
                    </Card> : null}

                {dataSuccess2 ?
                    <>
                        <Card>
                            <CardHeader className='d-flex'>
                                <CardLabel>
                                    <CardTitle>
                                        Over All Transfer Certificate
                                    </CardTitle>
                                </CardLabel>
                                <CardActions className='ms-auto'>
                                    <Button color='primary' isLight icon='Print' onClick={handlePrint}></Button>
                                </CardActions>
                            </CardHeader>
                        </Card>
                        <div ref={componentRef} >
                            {studentTCData.map((student: any) => (

                                <Card key={student.studentDetailsId} className="card-container" >
                                    <CardBody className="card-container">

                                        {collegeDetailsData.map((college: any) => (
                                            <table className="text-nowrap" cellPadding="10" cellSpacing="10" width="100%" key={college.collegeDetailsId}>
                                                <tbody>
                                                    <tr>
                                                        <td align="center" width="15%">
                                                            <img
                                                                height='120px'
                                                                width='120px'
                                                                src={CollegeLogo}
                                                            />
                                                        </td>
                                                        <td width="74%" align="center" style={{
                                                            fontFamily: 'Bookman Old Style',
                                                            color: 'rgb(61, 57, 57)'
                                                        }}>
                                                            <h1 className="text-uppercase">
                                                                <b style={{ fontSize: '120%' }}> {college.collegeName}</b>
                                                            </h1>

                                                            <p className="fs-6">({college.description})<br />{college.address}<br />
                                                                <Icon icon="Email" /> {college.website} <Icon icon="Phone" /> {college.phone} <Icon icon="PhoneAndroid" /> {college.cellNo}</p>
                                                        </td>
                                                        <td width="15%"></td>
                                                    </tr>
                                                    <tr>
                                                        <td align="center" width="15%"></td>
                                                        <td width="74%" align="center">
                                                            <h3 className="text-center fs-2 justify-content-center">
                                                                <strong style={{ fontFamily: 'Bookman Old Style', borderStyle: "Solid", color: 'rgb(61, 57, 57)', }}>&nbsp;TRANSFER CERTIFICATE&nbsp;
                                                                </strong></h3>
                                                        </td>
                                                        <td width="15%"></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        ))}
                                        <div className="row">

                                            <div className="row" >
                                                <div className="col-12 tcStyle" id='tdForReceiptNoBorder'>
                                                    <table className="table text-nowrap text-md-nowrap mg-b-0">
                                                        <tbody className="tcStyle fs-4">
                                                            <tr>
                                                                <td>
                                                                    <h4>Serial No :  {student.serialNo}</h4>
                                                                </td>
                                                                <td width="70%"></td>
                                                                <td>
                                                                    <h4 className="d-flex justify-content-end">Reg.No : {student.admissionNo}</h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    <table className="table text-nowrap">
                                                        <tbody className="fs-4">
                                                            <tr>
                                                                <td style={{ width: '1%' }}>1.</td>
                                                                <td style={{ width: '40%' }}> Name of the Student (in BLOCK LETTERS) <br />
                                                                    (as entered in +2 or equivalent Certificate) </td>
                                                                <td style={{ width: '1%' }}>:</td>
                                                                <td style={{ width: '20%' }} className="fw-bold">{student.studentName}</td>
                                                                <td width="28%" rowSpan=
                                                                    {4} align="right">
                                                                    {student.profilePath != null ?
                                                                        <img className="rounded-0" src={student.profilePath}
                                                                            width="140" height="140" />
                                                                        : <img className="rounded-0" src={noImage}
                                                                            width="140" height="140" />}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">2.</td>
                                                                <td width="40%">Name of the Father/Mother</td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.fatherOrMotherName}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">3.</td>
                                                                <td width="40%">Nationality and Religion</td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.nationality} - {student.religion}
                                                                </td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">4.</td>
                                                                <td width="40%">Community and Caste</td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.community} - {student.caste}</td>
                                                            </tr>

                                                            <tr>
                                                                <td width="1%">5.</td>
                                                                <td width="40%">Gender </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.gender}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">6.</td>
                                                                <td width="10%">Date of Birth as registered in <br /> the
                                                                    admission Register
                                                                </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%" colSpan={2}>
                                                                    {student.dateOfBirth_label}<br />{student.dateOfBirthInWord}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">7.</td>
                                                                <td width="40%">Date of Admission</td>
                                                                <td width="1%">:</td>
                                                                <td width="20%" colSpan={2}>{student.admissionDate_label}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">8.</td>
                                                                <td width="40%">Degree and Programme to which <br /> the student
                                                                    was admitted
                                                                </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%" colSpan={2}>{student.degreeAndCourse}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">9.</td>
                                                                <td width="40%">Class in which the student was studying <br /> at
                                                                    the time of
                                                                    leaving </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%" className="fw-bold" colSpan={2}>{student.timeOfLeaving}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">10.</td>
                                                                <td width="40%">a) Date on which the student actually left <br />
                                                                    the College
                                                                </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.leavingDate_label}</td>
                                                            </tr>
                                                            {ugOrPgStudent == 'isUgStudent' ?
                                                                <>
                                                                    <tr>
                                                                        <td width="1%"></td>
                                                                        <td width="40%" >b) Language offered under Part-1 </td>
                                                                        <td width="1%" >:</td>
                                                                        <td width="20%" >{student.languageOfferedUnderPart1} </td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td width="1%"></td>
                                                                        <td width="40%">c) Medium of Study </td>
                                                                        <td width="1%">:</td>
                                                                        <td width="20%">{student.medium}</td>
                                                                    </tr>
                                                                </>
                                                                : null}

                                                            {ugOrPgStudent == 'isNonUgStudent' ?
                                                                <tr>
                                                                    <td width="1%"></td>
                                                                    <td width="40%">b) Medium of Study </td>
                                                                    <td width="1%">:</td>
                                                                    <td width="20%">{student.medium}</td>
                                                                </tr> : null}
                                                            <tr>
                                                                <td width="1%">11.</td>
                                                                <td width="40%"> Reason for which the student left <br /> the
                                                                    College </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.leavingReason}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%" >12.</td>
                                                                <td width="40%" >Whether qualified for promotion to higher
                                                                    <br />studies
                                                                </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.qualifiedForHigherStudy}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">13.</td>
                                                                <td width="40%">Date of the Transfer Certificate </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.transferCertificateDate_label}</td>
                                                            </tr>
                                                            <tr>
                                                                <td width="1%">14.</td>
                                                                <td width="40%">Conduct and Character </td>
                                                                <td width="1%">:</td>
                                                                <td width="20%">{student.conductAndCharacter}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <table className="table text-nowrap text-md-nowrap mg-b-0">
                                                        <tbody className="tcStyle fs-4">
                                                            <tr>
                                                                <td>
                                                                    <h4>College Seal with Date</h4>
                                                                </td>
                                                                <td width="70%"></td>
                                                                <td>
                                                                    <h4>Signature of the Principal</h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <br />
                                                    <div className="col-12 tcStyle fs-4">
                                                        <label className="mg-b-10">1. Erasures and Unauthenticated or fraudulent in the Certificate will lead to its cancellation</label>
                                                    </div>
                                                    <div className="col-12 tcStyle fs-4 mt-2">
                                                        <label className="mg-b-10"> 2. Should be signed in ink by the Head or the Institution who will be held responsible for the <br />correctness of the entries</label>
                                                    </div>
                                                    <div className="col-12 tcStyle fs-4 mt-2">
                                                        <label className="mg-b-10">3. Declaration by the Parent Or Guardian</label>
                                                    </div>
                                                    <div className="col-12 tcStyle fs-4 mt-2">
                                                        <label className="mg-b-10">I hereby declare the particulars recorded against items 2 to 7 are correct and that no change will be demanded by me in future
                                                        </label>
                                                    </div>
                                                    <br />
                                                    <br />

                                                    <table className="table text-nowrap text-md-nowrap mg-b-0" id='tdForReceiptNoBorder'>
                                                        <tbody>
                                                            <tr>
                                                                <td>
                                                                    <h4>Signature of the Student</h4>
                                                                </td>
                                                                <td width="70%"></td>
                                                                <td>
                                                                    <h4>Signature of the Parent / Guardian</h4>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>

                                        </div>

                                    </CardBody>
                                </Card>

                            ))}
                        </div>
                    </> : null
                }
            </Page>

            <Modal
                isOpen={addStudentTcModal}
                setIsOpen={setAddStudentTcModal}
                id='addStudentTcModal'
                isStaticBackdrop={true}
                size={'lg'}>
                <ModalHeader setIsOpen={setAddStudentTcModal} onClick={closeAndReset}>
                    <ModalTitle id="addStudentTcModal">Add Student Transfer Certificate</ModalTitle>
                </ModalHeader>
                <form noValidate onSubmit={studentTransferCertificateForm.handleSubmit}>
                    <ModalBody>
                        <div className='row'>
                            <div className="d-grid gap-4">
                                <div className="d-flex justify-content-end">
                                    <div className="align-self-center" style={{ marginRight: '10px' }}>
                                        <p className="h6">Admission No :</p>
                                    </div>
                                    <div>
                                        <FormGroup id="admissionNo" label="" >
                                            <Input type="text" placeholder="Admission Number" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.admissionNo} onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.admissionNo} invalidFeedback={studentTransferCertificateForm.errors.admissionNo} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>

                                <div className="row justify-content-start">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>1.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="studentName" label="">
                                            <Input type="text" placeholder="Name Of Student" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.studentName} onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.studentName} invalidFeedback={studentTransferCertificateForm.errors.studentName} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>2.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Name of the Father or Mother of the Pupil </p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="fatherOrMotherName" label=""  >
                                            <Input type="text" placeholder="Name Of Father Or Mother" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.fatherOrMotherName} onBlur={studentTransferCertificateForm.handleBlur} isValid={studentTransferCertificateForm.isValid} isTouched={studentTransferCertificateForm.touched.fatherOrMotherName} invalidFeedback={studentTransferCertificateForm.errors.fatherOrMotherName} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>3.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="nationality" label="">
                                            <Input type="text" placeholder="Nationality" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.nationality} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>4.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="religion" label="">
                                            <Input type="text" placeholder="Religion"
                                                onChange={studentTransferCertificateForm.handleChange}
                                                value={studentTransferCertificateForm.values.religion} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>5.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Caste</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="caste" label="">
                                            <Input type="text" placeholder="Caste" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.caste} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>6.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Community</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="community" label="">
                                            <Input type="text" placeholder="Community" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.community} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>7.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="gender" label="">
                                            <Input type="text" placeholder="Male Or Female"
                                                onChange={studentTransferCertificateForm.handleChange}
                                                value={studentTransferCertificateForm.values.gender}
                                                onBlur={studentTransferCertificateForm.handleBlur}
                                                isValid={studentTransferCertificateForm.isValid}
                                                isTouched={studentTransferCertificateForm.touched.gender}
                                                invalidFeedback={studentTransferCertificateForm.errors.gender} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>8.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirth" label="">
                                            <Input type="date" placeholder="Date Of Birth"
                                                onChange={convertDateOfBirth}
                                                value={dateOfBirth} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>9.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirthInWord" label="">
                                            <Input type="text" placeholder="Date Of Birth"
                                                //onChange={studentTransferCertificateForm.handleChange} 
                                                value={dateOfBirthInWord} disabled />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>10.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date of Admission and class in which admitted</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="admissionDate" label="">
                                            <Input type="date" placeholder="Date of Admission" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.admissionDate} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>11.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Degree and Programme to which the student was admitted</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="degreeAndCourse" label="">
                                            <Input type="text" placeholder="Degree and Programme" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.degreeAndCourse} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>12.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="timeOfLeaving" label="">
                                            <Input type="text" placeholder="Class" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.timeOfLeaving} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>13.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date on which the student actually left the College</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingDate" label="">
                                            <Input type="date" placeholder="Date" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.leavingDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>14.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Reason for which the student left the College</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingReason" label="">
                                            <Input type="text" placeholder="Reason" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.leavingReason} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>15.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">
                                            Whether qualified for promotion to higher studies
                                        </p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="qualifiedForHigherStudy" label="">
                                            <Input type="text" placeholder="Qualified for promotion" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.qualifiedForHigherStudy} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>16.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date of the Transfer Certificate</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="transferCertificateDate" label="">
                                            <Input type="date" placeholder="Date Of Transfer Certificate" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.transferCertificateDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>17.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Conduct and Character</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="conductAndCharacter" label="">
                                            <Input type="text" placeholder="Conduct And Character" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.conductAndCharacter} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>18.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Medium(for M.Phil Only)</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="medium" label="">
                                            <Input type="text" placeholder="Medium" onChange={studentTransferCertificateForm.handleChange} value={studentTransferCertificateForm.values.medium} />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </ModalBody>
                    <ModalFooter>
                        <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                        <Button color='info' icon='Save' type="submit" isDisable={!studentTransferCertificateForm.isValid && !!studentTransferCertificateForm.submitCount}>Save </Button>
                    </ModalFooter>
                </form>
            </Modal>

            <Modal
                isOpen={updateStudentTcModal}
                setIsOpen={setUpdateStudentTcModal}
                id='updateStudentTcModal'
                isStaticBackdrop={true}
                size={'lg'}>
                <ModalHeader setIsOpen={setUpdateStudentTcModal} onClick={closeAndReset} >
                    <ModalTitle id="updateStudentTcModal">Update Transfer Certificate</ModalTitle>
                </ModalHeader>
                <ModalBody>
                    <form noValidate onSubmit={updateStudentTransferCertificateForm.handleSubmit}>
                        <div className='row'>
                            <div className="d-grid gap-4">
                                <div className='row'>
                                    <div className="d-flex col-lg-1"></div>
                                    <div className="d-flex col-lg-4">
                                        <div className="align-self-center" style={{ marginRight: '10px' }}>
                                            <p className="h6">Serial No :</p>
                                        </div>
                                        <div>
                                            <FormGroup id="serialNo" label="" >
                                                <Input type="text" placeholder="Serial Number" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.serialNo} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.serialNo} invalidFeedback={updateStudentTransferCertificateForm.errors.serialNo} disabled />
                                            </FormGroup>
                                        </div>
                                    </div>
                                    <div className="d-flex col-lg-1"></div>
                                    <div className="d-flex col-lg-6">
                                        <div className="align-self-center" style={{ marginRight: '10px' }}>
                                            <p className="h6">Admission No :</p>
                                        </div>
                                        <div>
                                            <FormGroup id="admissionNo" label="" >
                                                <Input type="text" placeholder="Admission Number" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.admissionNo}
                                                    //onBlur={updateStudentTransferCertificateForm.handleBlur} 
                                                    ///isValid={updateStudentTransferCertificateForm.isValid} 
                                                    //isTouched={updateStudentTransferCertificateForm.touched.admissionNo} //invalidFeedback={updateStudentTransferCertificateForm.errors.admissionNo} 
                                                    readOnly />
                                            </FormGroup>
                                        </div>
                                    </div>
                                </div>

                                <div className="row justify-content-start">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>1.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Name of the Student (in BLOCK LETTERS) (as entered in +2 or equivalent Certificate)</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="studentName" label="">
                                            <Input type="text" placeholder="Name Of Student" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.studentName} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.studentName} invalidFeedback={updateStudentTransferCertificateForm.errors.studentName} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>2.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Name of the Father or Mother of the Pupil </p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="fatherOrMotherName" label=""  >
                                            <Input type="text" placeholder="Name Of Father Or Mother" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.fatherOrMotherName} onBlur={updateStudentTransferCertificateForm.handleBlur} isValid={updateStudentTransferCertificateForm.isValid} isTouched={updateStudentTransferCertificateForm.touched.fatherOrMotherName} invalidFeedback={updateStudentTransferCertificateForm.errors.fatherOrMotherName} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>3.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Nationality</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="nationality" label="">
                                            <Input type="text" placeholder="Nationality" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.nationality} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>4.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Religion</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="religion" label="">
                                            <Input type="text" placeholder="Religion"
                                                onChange={updateStudentTransferCertificateForm.handleChange}
                                                value={updateStudentTransferCertificateForm.values.religion} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>5.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Caste</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="caste" label="">
                                            <Input type="text" placeholder="Caste" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.caste} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>6.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Community</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="community" label="">
                                            <Input type="text" placeholder="Community" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.community} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>7.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Gender</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="gender" label="">
                                            <Input type="text" placeholder="Male Or Female"
                                                onChange={updateStudentTransferCertificateForm.handleChange}
                                                value={updateStudentTransferCertificateForm.values.gender}
                                                onBlur={updateStudentTransferCertificateForm.handleBlur}
                                                isValid={updateStudentTransferCertificateForm.isValid}
                                                isTouched={updateStudentTransferCertificateForm.touched.gender}
                                                invalidFeedback={updateStudentTransferCertificateForm.errors.gender} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>8.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth as entered in the Admission</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirth" label="">
                                            <Input type="date" placeholder="Date Of Birth"
                                                onChange={convertDateOfBirth}
                                                value={dateOfBirth} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>9.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center"><p className="h6">Date of Birth in Word</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="dateOfBirthInWord" label="">
                                            <Input type="text" placeholder="Date Of Birth"
                                                onChange={updateStudentTransferCertificateForm.handleChange}
                                                value={dateOfBirthInWord} disabled />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>10.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date of Admission and class in which admitted</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="admissionDate" label="">
                                            <Input type="date" placeholder="Date of Admission" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.admissionDate} readOnly />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>11.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Degree and Programme to which the student was admitted</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="degreeAndCourse" label="">
                                            <Input type="text" placeholder="Degree and Programme" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.degreeAndCourse} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>12.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Class in which the pupil was studying at the time of leaving</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="timeOfLeaving" label="">
                                            <Input type="text" placeholder="Class" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.timeOfLeaving} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>13.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date on which the student actually left the College</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingDate" label="">
                                            <Input type="date" placeholder="Date" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.leavingDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>14.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Reason for which the student left the College</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="leavingReason" label="">
                                            <Input type="text" placeholder="Reason" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.leavingReason} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>15.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">
                                            Whether qualified for promotion to higher studies
                                        </p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="qualifiedForHigherStudy" label="">
                                            <Input type="text" placeholder="Qualified for promotion" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.qualifiedForHigherStudy} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>16.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Date of the Transfer Certificate</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="transferCertificateDate" label="">
                                            <Input type="date" placeholder="Date Of Transfer Certificate" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.transferCertificateDate} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>17.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Conduct and Character</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="conductAndCharacter" label="">
                                            <Input type="text" placeholder="Conduct And Character" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.conductAndCharacter} />
                                        </FormGroup>
                                    </div>
                                </div>
                                <div className="row justify-content-center">
                                    <div className="col-lg-1 align-self-center h6">
                                        <p>18.</p>
                                    </div>
                                    <div className="col-lg-5 align-self-center">
                                        <p className="h6">Medium(for M.Phil Only)</p>
                                    </div>
                                    <div className="col-lg-1 align-self-center"><p className="h6">:</p></div>
                                    <div className="col-lg-5 justify-content-center">
                                        <FormGroup id="medium" label="">
                                            <Input type="text" placeholder="Medium" onChange={updateStudentTransferCertificateForm.handleChange} value={updateStudentTransferCertificateForm.values.medium} />
                                        </FormGroup>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <ModalFooter>
                            <Button color='info' isOutline className='border-0' onClick={closeAndReset}>Close</Button>
                            <Button icon="Print" color='success' onClick={() => window.open(`../academic/commonTc/${studentDetailsId}`)}>Print</Button>
                            <Button color='info' icon='Save' type="submit" isDisable={!updateStudentTransferCertificateForm.isValid && !!updateStudentTransferCertificateForm.submitCount}>Update </Button>
                        </ModalFooter>
                    </form>
                </ModalBody>
            </Modal>

            <Modal
                isOpen={pendingTCApprovalModal}
                setIsOpen={setPendingTCApprovalModal}
                id='pendingTCApprovalModal'
                isStaticBackdrop={true}
                size='sm'>
                <ModalHeader setIsOpen={setPendingTCApprovalModal} onClick={closeAndReset}>
                    <ModalTitle id="pendingTCApprovalModal">Status</ModalTitle>
                </ModalHeader>
                {/* <ModalBody> */}
                <div className='row align-middle'>
                    <h6 className="main-content-label mb-1 text-center text-danger">{tcStatusMsg}</h6>
                    <br />
                    <br />
                </div>
                {/* </ModalBody> */}
            </Modal>
            <AlertService setIsOpen={setIsOpen} isOpen={isOpen} alertStatus={alertStatus} />
        </PageWrapper >
    )
}
export default StudentTransferCertificate
