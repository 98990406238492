import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import Button from "../../bootstrap/Button";
import Card, { CardBody } from "../../bootstrap/Card";
import Page from "../../../layout/Page/Page";
import PageWrapper from "../../../layout/PageWrapper/PageWrapper";
import SubHeader, { SubHeaderLeft, SubheaderSeparator } from "../../../layout/SubHeader/SubHeader";
import { getCollegeDetails } from "../../../services/college.service";
import { toasts } from "../../../services/toast.service";
import CollegeLogo from '../../../assets/logos/logo.jpg';
import { getCircularDetails } from "../../../services/management.service";
import Icon from "../../icon/Icon";

const CircularContent = () => {

	const navigate = useNavigate();

	useEffect(() => {

		getCircularList();
		getCollege();
	}, []);

	const [dataStatus, setDataStatus] = useState(false);
	const [circularData, setCircularData] = useState<any>([]);
	const [collegeDetailsData, setCollegeDetailsData] = useState<any>([]);

	const { circularDetailsId } = useParams();

	const componentRef = useRef(null);

	function getCircularList() {
		getCircularDetails(
			circularDetailsId,
			(response) => {
				if (response.data.success) {
					let data = response.data.data.circularDetails;
					if (data != undefined) {
						setDataStatus(true);
						setCircularData(data);
					} else {
						setDataStatus(false);
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					setDataStatus(true);
					// toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					setDataStatus(true);
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				setDataStatus(true);
				toasts(error, 'Error');
			},
		);
	}

	function getCollege() {
		getCollegeDetails(
			(response) => {
				if (response.data.success) {
					let data = response.data.data.collegeDetails[0];
					if (data != undefined) {
						setCollegeDetailsData(data);
					} else {
						toasts('Undefined Data', 'Error');
					}
				} else if (response.data.success === false) {
					toasts(response.data.message, 'Error');
				} else {
					let errorCode = response.data.error[0].error_code;
					let errorDescription = response.data.error[0].error_description;
					toasts(errorDescription, 'Error');
				}
			},
			(error) => {
				toasts(error, 'Error');
			},
		);
	}

	const handlePrint = useReactToPrint({
		content: () => componentRef.current,
	});
	return (

		<PageWrapper title={'Circular'}>
			<SubHeader>
				<SubHeaderLeft>
					<Button color='info' isLink icon='ArrowBack' onClick={() => navigate(-1)}>
						Back to List
					</Button>
					<SubheaderSeparator />
				</SubHeaderLeft>
			</SubHeader>
			<Page container='fluid'>
				<div className='row '>
					<div className='col-12 '>
						{circularData.map((i: any) => (
							<Card key={i.circularDetailsId}>
								<CardBody className='table-responsive'>
									<div className='d-flex col-12 justify-content-end mt-4'>
										<Button icon='Print' isLight color='primary' onClick={() => { handlePrint() }}>
											Print
										</Button>
									</div>
									<table ref={componentRef}
										cellPadding={10}
										cellSpacing={10}
										width='100%'
										style={{ fontFamily: 'Times New Roman' }}>
										<tbody style={{ borderBottom: 'solid 1px black' }}>
											<tr>
												<td align='center' width='20%'>
													<img
														height='100px'
														width='100px'
														src={CollegeLogo}
													/>
												</td>
												<th
													className='text-center'
													align='center'
													style={{
														fontFamily: 'Bookman Old Style',
														width: '60%',
													}}>
													<h1 className='text-uppercase'>
														<b style={{ fontSize: '90%' }}>
															{collegeDetailsData.collegeName}
														</b>
													</h1>
													<p className='fs-6'>
														({collegeDetailsData.description})<br />
														{collegeDetailsData.address}
														<br />
														<Icon icon='Email' />{' '}
														{collegeDetailsData.website}{' '}
														<Icon icon='Phone' />{' '}
														{collegeDetailsData.phone}{' '}
														<Icon icon='PhoneAndroid' />{' '}
														{collegeDetailsData.cellNo}
													</p>
												</th>
												<td width='20%'></td>
											</tr>
										</tbody>
										<tbody>
											<tr>
												<th style={{ width: '24%' }}>
													<p style={{ float: 'left', fontSize: '142%' }}>
														Reference No : {i.referenceNo}
													</p>
												</th>
												<th className='text-center'>
													<h2 className='mt-5' style={{ fontFamily: 'Bookman Old Style' }}>
														<u>CIRCULAR</u>
													</h2>
													<br />
													<h2 className='text-uppercase' style={{ fontFamily: 'Bookman Old Style' }}>
														<u>{i.subject}</u>
													</h2>
												</th>
												<th style={{ width: '25%' }}>
													<p style={{ float: 'right', fontSize: '142%' }}>
														Date : {i.circularDateForView}
													</p>
												</th>
											</tr>
											<tr>
												<td
													width='100%'
													colSpan={4}
													className='fs-4'
													style={{ fontFamily: 'Bookman Old Style' }}>
													<div dangerouslySetInnerHTML={{ __html: i.content }} />
													<br />
													<br />
													<br />
													<label
														style={{
															float: 'right',
															marginRight: '20px',
														}}
														className='fw-bold'>
														PRINCIPAL
													</label>
												</td>
											</tr>
										</tbody>
									</table>
									<br />
									<br />
									<br />
								</CardBody>
							</Card>
						))}
					</div>
				</div>
			</Page>
		</PageWrapper>
	);
};

export default CircularContent;
