import { useContext, useEffect, useState } from 'react';
import { toasts } from '../../../services/toast.service';
import Card from '../../bootstrap/Card';
import './newsContainer.css'
import AuthContext from '../../../contexts/authContext';
import { getAnnouncementDetails } from '../../../services/management.service';

function Announcement() {

    useEffect(() => {
        getAnnouncement();
    }, []);

    const { userAccountId, userTypeId } = useContext(AuthContext);
    const [announcementDetailsData, setAnnouncementDetailsData] = useState<any>([])
    const [noDataMsg, setNoDataMsg] = useState<any>([])

    function getAnnouncement() {
        getAnnouncementDetails(
            userTypeId,
            (response) => {
                if (response.data.success) {
                    let data = response.data.data.announcementDetails;
                    if (data != undefined) {
                        setAnnouncementDetailsData(data);
                    } else {
                        toasts('Undefined Data', 'Error');
                    }
                } else if (response.data.success === false) {
                    //toasts(response.data.message, "Error")
                    setAnnouncementDetailsData([]);
                    setNoDataMsg(response.data.message)
                } else {
                    let errorCode = response.data.error[0].error_code;
                    let errorDescription = response.data.error[0].error_description;
                    toasts(errorDescription, 'Error');
                }
            },
            (error) => {
                toasts(error, 'Error');
            },
        );
    }
    return (
        <>
            <Card className='border border-light rounded-1'>
                <div className="news-container">
                    <div className="title">
                        Announcement
                    </div>
                    <ul>
                        {announcementDetailsData != '' ?
                            <>
                                {announcementDetailsData.map((item: any) =>
                                    <li key={item.announcementDetailsId}>
                                        <span className='fs-6 fw-bold'>{item.title}</span>
                                    </li>
                                )}
                            </>
                            :
                            <li>
                                <b>{noDataMsg}</b>
                            </li>
                        }
                    </ul>
                </div>
            </Card>

        </>
    )
}
export default Announcement;